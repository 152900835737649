import { createRawMenuItems } from 'config/createRawMenuItems.tsx';

export function getRawMenuItems(opConfig, orgConfig) {
  return createRawMenuItems(opConfig, orgConfig);
}

export function getItemConfig(items, route) {
  let item = null;
  for (let i = 0; i < items.length; i++) {
    if (items[i].route === route) {
      item = items[i];
    }
    if (items[i].items) {
      const testItem = getItemConfig(items[i].items, route);
      if (testItem) {
        return testItem;
      }
    }
  }
  return item;
}
