import moment from 'moment';
import momentTimezone from 'moment-timezone';
import {
  DATE_TIME_WITH_SECONDS_FORMAT,
  MINIMAL_DATE_FORMAT,
  MINIMAL_TIME_FORMAT,
  TIME_WITH_SECONDS_FORMAT,
} from 'constants/Dates';
import { t } from 'i18next';
import dayjs from 'dayjs';
import dayjsMinMax from 'dayjs/plugin/minMax';
import dayjsUtc from 'dayjs/plugin/utc';
import dayjsTimezone from 'dayjs/plugin/timezone';
import dayjsCustomParseFormat from 'dayjs/plugin/customParseFormat';
import dayjsQuarterOfTheYear from 'dayjs/plugin/quarterOfYear';
import dayOfYear from 'dayjs/plugin/dayOfYear';

dayjs.extend(dayjsMinMax);
dayjs.extend(dayjsUtc);
dayjs.extend(dayjsTimezone);
dayjs.extend(dayjsCustomParseFormat);
dayjs.extend(dayjsQuarterOfTheYear);
dayjs.extend(dayOfYear);

export const opTime = dayjs;

/**
 * combineMinimalDateTimeAndTimezone
 * @param {String} minimalDate four-digit year, two digit month, two digit days
 *  ex) '2018-12-06'
 * @param {String} minimalTime (optional) two-digit hour, two digit minutes, two digit seconds
 *  Defaults to '00:00:00'
 *  ex) '14:00:00'
 * @param {String} timezone (optional)
 *  Defaults to current timezone
 *  ex) 'America/Los_Angeles'
 * @return {Moment-Timezone instance} New object created with moment(...).format(...).tz(...)
 */
export function combineMinimalDateTimeAndTimezone(
  minimalDate,
  minimalTime = '00:00:00',
  timezone = momentTimezone.tz.guess(),
) {
  if (!minimalDate) {
    return null;
  }

  return moment.tz(`${minimalDate} ${minimalTime}`, timezone);
}

export function separateMinimalDateTime(combinedDateAndTimeInZulu) {
  return {
    date: moment(combinedDateAndTimeInZulu).format(MINIMAL_DATE_FORMAT),
    time: moment(combinedDateAndTimeInZulu).format(MINIMAL_TIME_FORMAT),
  };
}

/**
 * getDurationSummary
 * @param {String} minimalStartDate
 * @param {String} minimalStartTime
 * @param {String} minimalEndDate
 * @param {String} minimalEndTime
 * @param {String} timezone (optional)
 *   Defaults to local timezone
 *   Ex: 'America/Los_Angeles'
 * @returns {String}
 *   Ex: '(Spans 2 hours)'
 *       '(Spans 1 hour 3 minutes)'
 *       '(Spans 3 minutes)'
 *       '(Must be later than start)'
 */
export function getDurationSummary(
  minimalStartDate,
  minimalStartTime,
  minimalEndDate,
  minimalEndTime,
  timezone = momentTimezone.tz.guess(),
) {
  const startDate = combineMinimalDateTimeAndTimezone(
    minimalStartDate,
    minimalStartTime,
    timezone,
  );

  const endDate = combineMinimalDateTimeAndTimezone(
    minimalEndDate,
    minimalEndTime,
    timezone,
  );

  // If we went over a daylight savings time boundary, then move the endDate backwards or forwards to hide the change from the user
  const deltaOffsetInMinutes = endDate.utcOffset() - startDate.utcOffset();
  if (deltaOffsetInMinutes !== 0) {
    endDate.add(deltaOffsetInMinutes, 'minutes');
  }

  const duration = moment.duration(endDate.diff(startDate));
  const numAsDays = duration.asDays();
  const numHours = duration.hours();
  const numMinutes = duration.minutes();
  const numSeconds = duration.seconds();

  const minutesClause =
    numMinutes !== 0 ? ' ' + t('{{count}} minutes', { count: numMinutes }) : '';
  const secondsClause =
    numSeconds !== 0 ? ' ' + t('{{count}} seconds', { count: numSeconds }) : '';

  if (
    Math.round(numAsDays) === 1 &&
    numHours === 0 &&
    numMinutes === 0 &&
    numSeconds === 0
  ) {
    return t('(Spans 24 hours)');
  }

  if (endDate <= startDate) {
    return t('(Must be later than start)');
  }

  const daysClause =
    Math.floor(numAsDays) !== 0
      ? ' ' + t('{{count}} days', { count: Math.floor(numAsDays) })
      : '';
  const hoursClause =
    numHours !== 0 ? ' ' + t('{{count}} hours', { count: numHours }) : '';
  return `(${t(
    'Spans',
  )}${daysClause}${hoursClause}${minutesClause}${secondsClause})`;
}

export function getTodaysDateAsMinimalDate() {
  return moment().format(MINIMAL_DATE_FORMAT);
}

export function convertMinimalDateToJSDate(minimalDate) {
  if (!isValidMinimalDate(minimalDate)) {
    // Return empty string to allow timeDatePickers to be empty
    return '';
  }

  return moment.tz(minimalDate, momentTimezone.tz.guess()).toDate();
}

export function convertMinimalTimeToJSDate(minimalTime) {
  if (!isValidMinimalTime(minimalTime)) {
    // Return empty string to allow timeDatePickers to be empty
    return '';
  }

  return moment
    .tz(
      `${getTodaysDateAsMinimalDate()} ${minimalTime}`,
      momentTimezone.tz.guess(),
    )
    .toDate();
}

/**
 * isValidMinimalDate
 * @param {String} minimalDate
 *   Must be in 'YYYY-MM-DD format'
 *   Ex: '2018-06-05'
 * @returns {Bool} True if minimalDate is a valid date
 */
export function isValidMinimalDate(minimalDate) {
  return /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(minimalDate);
}

/**
 * isValidMinimalTime
 * @param {String} minimalTime
 *   Must be in 24 hour 'HH:mm:ss format'
 *   Ex: '14:30:00'
 * @returns {Bool} True if minimalTime is a valid time
 */
export function isValidMinimalTime(minimalTime) {
  return /(?:[01]\d|2[0123]):(?:[012345]\d):(?:[012345]\d)/.test(minimalTime);
}

/**
 * combineDateTime
 * @param {Date} startDate
 * @param {String} startTime (hh:mm:ss)
 */
export function combineDateTime(startDate, startTime) {
  const mStart = moment(startTime, TIME_WITH_SECONDS_FORMAT);
  return moment(startDate)
    .set({
      hour: mStart.get('hour'),
      minute: mStart.get('minute'),
      second: mStart.get('second'),
    })
    .toDate();
}

export function getMonthAbbreviation(timestamp) {
  const month = moment(timestamp).format('MMM');

  switch (month) {
    case 'May':
      return 'May';

    case 'June':
      return 'June';

    case 'Jun':
      return 'June';

    default:
      return `${month}.`;
  }
}

/**
 * Ex)
 * @param {String} date represented as a string
 * @param {Bool} allowShortcuts
 *    If allowShortcuts is True, returns:
 *      'Today'
 *      'Yesterday'
 *    If allowShortcuts is False, returns:
 *      'June 6, 2018'
 * @returns {String}
 *   Ex) 'Today' or 'June 6, 2018'
 */
export function getFormattedDate(timestamp, allowShortcuts, addTime) {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const currentDateString = new Date(timestamp).toDateString();

  if (allowShortcuts) {
    if (today.toDateString() === currentDateString) {
      return addTime ? t('Today at') : t('Today');
    }
    if (yesterday.toDateString() === currentDateString) {
      return addTime ? t('Yesterday at') : t('Yesterday');
    }
  }

  return `${getMonthAbbreviation(timestamp)} ${moment(timestamp).format(
    'D, YYYY',
  )}`;
}

/**
 * Ex)
 *    If allowShortcuts is True:
 *      'Today 4:42 pm  PDT'
 *      'Yesterday 4:42 pm PDT'
 *    If allowShortcuts is False:
 *      'June 6, 2018 4:42 pm PDT'
 *
 * @param {string} timestamp
 * @param {bool} allowShortcuts
 */
export function getFormattedDateAndTimeWithTimezone(timestamp, allowShortcuts) {
  return `${getFormattedDate(timestamp, allowShortcuts, true)} ${moment(
    timestamp,
  ).format(`${TIME_WITH_SECONDS_FORMAT} z`)}`;
}

export function getFormattedDateAndTime(timestamp, allowShortcuts) {
  // if this returns Jan 1, 1970 - timestamp is likely in seconds. Multiply timestamp by 1000 to get ms.
  return `${getFormattedDate(timestamp, allowShortcuts, true)} ${moment(
    timestamp,
  ).format(TIME_WITH_SECONDS_FORMAT)}`;
}

/**
 * Ex)
 *   '2017-09-05'
 *
 * @param {string} timestamp
 */
export function getCompactDate(timestamp) {
  return moment(timestamp).format(MINIMAL_DATE_FORMAT);
}

/**
 * Ex)
 *   '2017-09-05'
 *
 * @param {string} timestamp
 */
export function getCompactTime(timestamp) {
  return moment(timestamp).format(TIME_WITH_SECONDS_FORMAT);
}

/**
 * Ex)
 *   '2017-09-05 3:42:35 pm'
 *
 * @param {string} timestamp
 */
export function getCompactDateAndTime(timestamp) {
  return timestamp
    ? moment(timestamp).format(DATE_TIME_WITH_SECONDS_FORMAT)
    : null;
}

// The functions below have been created as Day.js equivalents of the original Moment.js functions.
// We have not modified the original functions to avoid a large testing surface. These new functions
// allow us to migrate incrementally with minimal risk. Once all instances in the codebase have been
// migrated to these new Day.js-based functions, we will perform a mass non-functional renaming  to
// remove the '_opTime' suffix.
export function getCompactDate_opTime(timestamp) {
  return opTime(timestamp).format(MINIMAL_DATE_FORMAT);
}

export function getCompactTime_opTime(timestamp) {
  return opTime(timestamp).format(TIME_WITH_SECONDS_FORMAT);
}

export function getCompactDateAndTime_opTime(timestamp) {
  return timestamp
    ? opTime(timestamp).format(DATE_TIME_WITH_SECONDS_FORMAT)
    : null;
}

export function dateGreaterThan(value, targetValue) {
  const valueDate = new Date(value);
  const targetValueDate = new Date(targetValue);

  return valueDate.getTime() > targetValueDate.getTime();
}

/**
 * @param {number} secondsFromUnixEpoch
 * @param {Partial<{timezone: string, format: string, showTimezone: boolean}>} [config]
 * @returns {string}
 */
export function convertSecondsToReadableDate(
  secondsFromUnixEpoch,
  {
    format = DATE_TIME_WITH_SECONDS_FORMAT,
    timezone,
    showTimezone = false,
  } = {},
) {
  const unixTime = secondsFromUnixEpoch * 1000;
  const finalTImeZone = timezone ?? opTime.tz.guess();
  const time = showTimezone
    ? opTime(unixTime).tz(finalTImeZone).format(format)
    : opTime(unixTime).format(format);

  return time;
}

/**
 * subtractSecondFromTime
 * @param {String} time Ex) "00:00:00"
 * @returns {String} time with one second less. Ex) "23:59:59"
 */
export function subtractSecondFromTime(time) {
  try {
    return moment(`2018-02-01 ${time}`)
      .subtract(1, 's')
      .format(MINIMAL_TIME_FORMAT);
  } catch {
    return time;
  }
}

export function subtractDayFromDate(date) {
  try {
    return moment(`${date} 00:00:00`)
      .subtract(1, 'd')
      .format(MINIMAL_DATE_FORMAT);
  } catch {
    return date;
  }
}

export function addDayToDate(date) {
  try {
    return moment(`${date} 00:00:00`).add(1, 'd').format(MINIMAL_DATE_FORMAT);
  } catch {
    return date;
  }
}

export function addSecondToTime(time) {
  try {
    return moment(`2018-02-01 ${time}`).add(1, 's').format(MINIMAL_TIME_FORMAT);
  } catch {
    return time;
  }
}

/**
 * roundUpDateTime
 *  Source: https://stackoverflow.com/a/37685886
 * @param {Object} momentObj
 * @param {String} roundBy ("hour"|"minute"|"second")
 */
export function roundUpDateTime(momentObj, roundBy = 'minute') {
  return momentObj
    .clone()
    .subtract(1, 'millisecond')
    .add(1, roundBy)
    .startOf(roundBy);
}

/**
 * timeCompare
 * @param {String} timeA in 'HH:mm:ss' format ex) '08:40:00'
 * @param {String} timeB in 'HH:mm:ss' format ex) '08:40:00'
 * @return {Int}
 *  -1 if timeA is before timeB
 *  0 if they are identical strings
 *  1 if timeA is after timeB
 */
export function timeCompare(timeA, timeB) {
  if (timeA === timeB) {
    return 0;
  }

  const ARBITRARY_DATE = '2018-01-01';

  return moment(`${ARBITRARY_DATE} ${timeA}`).isAfter(
    moment(`${ARBITRARY_DATE} ${timeB}`),
  )
    ? 1
    : -1;
}

export const generateUnixFromNMinutesAgo = (n) =>
  moment().subtract(n, 'minutes').valueOf() / 1000;

export const opMoment = moment;

export const formatSecondsToClock = (seconds) => {
  if (!isFinite(seconds)) {
    console.error(`Invalid input for formatSecondsToClock: ${seconds}`);
    return null;
  }

  let format = 'H:mm:ss';
  if (seconds < 3600) {
    format = 'mm:ss';
  }
  if (seconds < 600) {
    format = 'm:ss';
  }

  return opMoment.utc(seconds * 1000).format(format);
};

export const calculateDateRangeFromPreset = (preset) => {
  let newFormValues = {
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null,
  };
  switch (preset) {
    case 'yesterday':
      {
        const startTime = moment()
          .subtract(24, 'hours')
          .startOf('day')
          .format(MINIMAL_TIME_FORMAT);
        const startDate = moment()
          .subtract(24, 'hours')
          .startOf('day')
          .format(MINIMAL_DATE_FORMAT);
        const endTime = moment()
          .subtract(24, 'hours')
          .endOf('day')
          .format(MINIMAL_TIME_FORMAT);
        const endDate = moment()
          .subtract(24, 'hours')
          .endOf('day')
          .format(MINIMAL_DATE_FORMAT);

        newFormValues = {
          startDate,
          startTime,
          endDate,
          endTime,
        };
      }
      break;
    case 'lastHour':
      {
        const startTime = moment()
          .subtract(1, 'hours')
          .startOf('hour')
          .format(MINIMAL_TIME_FORMAT);
        const startDate = moment()
          .subtract(1, 'hours')
          .startOf('hour')
          .format(MINIMAL_DATE_FORMAT);
        const endTime = moment().format(MINIMAL_TIME_FORMAT);
        const endDate = moment().format(MINIMAL_DATE_FORMAT);

        newFormValues = {
          startDate,
          startTime,
          endDate,
          endTime,
        };
      }
      break;
    case 'last4Hours':
      {
        const startTime = moment()
          .subtract(4, 'hours')
          .startOf('hour')
          .format(MINIMAL_TIME_FORMAT);
        const startDate = moment()
          .subtract(4, 'hours')
          .startOf('hour')
          .format(MINIMAL_DATE_FORMAT);
        const endTime = moment().format(MINIMAL_TIME_FORMAT);
        const endDate = moment().format(MINIMAL_DATE_FORMAT);

        newFormValues = {
          startDate,
          startTime,
          endDate,
          endTime,
        };
      }
      break;
    case 'last12Hours':
      {
        const startTime = moment()
          .subtract(12, 'hours')
          .startOf('hour')
          .format(MINIMAL_TIME_FORMAT);
        const startDate = moment()
          .subtract(12, 'hours')
          .startOf('hour')
          .format(MINIMAL_DATE_FORMAT);
        const endTime = moment().format(MINIMAL_TIME_FORMAT);
        const endDate = moment().format(MINIMAL_DATE_FORMAT);

        newFormValues = {
          startDate,
          startTime,
          endDate,
          endTime,
        };
      }
      break;
    case 'last24Hours':
      {
        const startTime = moment()
          .subtract(24, 'hours')
          .startOf('hour')
          .format(MINIMAL_TIME_FORMAT);
        const startDate = moment()
          .subtract(24, 'hours')
          .startOf('hour')
          .format(MINIMAL_DATE_FORMAT);
        const endTime = moment().format(MINIMAL_TIME_FORMAT);
        const endDate = moment().format(MINIMAL_DATE_FORMAT);

        newFormValues = {
          startDate,
          startTime,
          endDate,
          endTime,
        };
      }
      break;
    case 'today':
      {
        const startTime = moment().startOf('day').format(MINIMAL_TIME_FORMAT);
        const startDate = moment().startOf('day').format(MINIMAL_DATE_FORMAT);
        const endTime = moment().format(MINIMAL_TIME_FORMAT);
        const endDate = moment().format(MINIMAL_DATE_FORMAT);

        newFormValues = {
          startDate,
          startTime,
          endDate,
          endTime,
        };
      }
      break;
    case 'last7Days':
      {
        const startTime = moment()
          .subtract(7, 'days')
          .startOf('day')
          .format(MINIMAL_TIME_FORMAT);
        const startDate = moment()
          .subtract(7, 'days')
          .startOf('day')
          .format(MINIMAL_DATE_FORMAT);
        const endTime = moment().format(MINIMAL_TIME_FORMAT);
        const endDate = moment().format(MINIMAL_DATE_FORMAT);

        newFormValues = {
          startDate,
          startTime,
          endDate,
          endTime,
        };
      }
      break;
    case 'lastFullWeek':
      {
        const startTime = moment()
          .subtract(1, 'weeks')
          .startOf('week')
          .format(MINIMAL_TIME_FORMAT);
        const startDate = moment()
          .subtract(1, 'weeks')
          .startOf('week')
          .format(MINIMAL_DATE_FORMAT);
        const endTime = moment()
          .subtract(1, 'weeks')
          .endOf('week')
          .format(MINIMAL_TIME_FORMAT);
        const endDate = moment()
          .subtract(1, 'weeks')
          .endOf('week')
          .format(MINIMAL_DATE_FORMAT);

        newFormValues = {
          startDate,
          startTime,
          endDate,
          endTime,
        };
      }
      break;
    case 'last30Days':
      {
        const startTime = moment()
          .subtract(30, 'days')
          .startOf('day')
          .format(MINIMAL_TIME_FORMAT);
        const startDate = moment()
          .subtract(30, 'days')
          .startOf('day')
          .format(MINIMAL_DATE_FORMAT);
        const endTime = moment().format(MINIMAL_TIME_FORMAT);
        const endDate = moment().format(MINIMAL_DATE_FORMAT);

        newFormValues = {
          startDate,
          startTime,
          endDate,
          endTime,
        };
      }
      break;
    case 'lastFullMonth':
      {
        const startTime = moment()
          .subtract(1, 'months')
          .startOf('month')
          .format(MINIMAL_TIME_FORMAT);
        const startDate = moment()
          .subtract(1, 'months')
          .startOf('month')
          .format(MINIMAL_DATE_FORMAT);
        const endTime = moment()
          .subtract(1, 'months')
          .endOf('month')
          .format(MINIMAL_TIME_FORMAT);
        const endDate = moment()
          .subtract(1, 'months')
          .endOf('month')
          .format(MINIMAL_DATE_FORMAT);

        newFormValues = {
          startDate,
          startTime,
          endDate,
          endTime,
        };
      }
      break;
    case 'lastFullQuarter':
      {
        const startTime = moment()
          .subtract(1, 'quarter')
          .startOf('quarter')
          .format(MINIMAL_TIME_FORMAT);
        const startDate = moment()
          .subtract(1, 'quarter')
          .startOf('quarter')
          .format(MINIMAL_DATE_FORMAT);
        const endTime = moment()
          .subtract(1, 'quarter')
          .endOf('quarter')
          .format(MINIMAL_TIME_FORMAT);
        const endDate = moment()
          .subtract(1, 'quarter')
          .endOf('quarter')
          .format(MINIMAL_DATE_FORMAT);

        newFormValues = {
          startDate,
          startTime,
          endDate,
          endTime,
        };
      }
      break;
  }

  return { ...newFormValues, derivedFromPreset: preset };
};

export const VALID_PRESETS = [
  'today',
  'lastHour',
  'last4Hours',
  'last12Hours',
  'last24Hours',
  'last7Days',
  'last30Days',
  'yesterday',
  'lastFullWeek',
  'lastFullMonth',
  'lastFullQuarter',
];
