import React from 'react';
import {
  SvgWrapper,
  SvgWrapperProps,
} from 'components/svgs/SvgWrapper/SvgWrapper';

interface UserOutlinedSvgProps extends Omit<SvgWrapperProps, 'children'> {}

export const UserOutlinedSvg = ({ ...svgProps }: UserOutlinedSvgProps) => (
  <SvgWrapper viewBox="0 0 18 18" {...svgProps}>
    <path d="M16 15.998H2.004V14.5C2.004 12.571 3.573 11.002 5.502 11.002H12.502C14.431 11.002 16 12.571 16 14.5V15.998ZM5.002 6C5.002 3.794 6.796 2 9.002 2C11.208 2 13.002 3.794 13.002 6C13.002 8.199 11.218 9.987 9.021 9.998H8.982C6.786 9.987 5.002 8.199 5.002 6ZM12.502 9.998H11.977C13.199 9.086 14.002 7.642 14.002 6C14.002 3.239 11.763 1 9.002 1C6.241 1 4.002 3.239 4.002 6C4.002 7.642 4.805 9.086 6.027 9.998H5.502C3.02 9.998 1 12.018 1 14.5V16.5C1 16.777 1.225 17.002 1.502 17.002H16.502C16.779 17.002 17.004 16.777 17.004 16.5V14.5C17.004 12.018 14.984 9.998 12.502 9.998Z" />
  </SvgWrapper>
);
