import { createContext, ReactNode, useContext, useMemo } from 'react';

interface AppContextProps {
  app: 'access' | 'partnerCenter' | 'mailroom' | ''; // use '' to make sure checks on this dont blow up as its still a string
  children?: ReactNode;
}

export const AppContext = createContext<AppContextProps | undefined>(undefined);

export const AppProvider = ({ children, app }: AppContextProps) => {
  const contextValue = useMemo(() => ({ app }), [app]);

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

export const useAppContext = (): AppContextProps => {
  const context = useContext(AppContext);

  return context || { app: '' };
};
