import clsx from 'clsx';
import { CSSProperties, ReactNode } from 'react';

import './SvgWrapper.scss';

export interface SvgWrapperProps {
  children: ReactNode;
  rootClassName?: string;
  className?: string;
  width?: string | number;
  height?: string | number;
  viewBox?: string;
  opacity?: string;
  fill?: string;
  stroke?: string;
  strokeWidth?: string | number;
  style?: CSSProperties;
}

export const SvgWrapper = ({
  children,
  rootClassName = '',
  className = '',
  width = '1em',
  height = '1em',
  viewBox = '0 0 18 18',
  opacity = '1',
  fill = 'currentColor',
  stroke = 'currentColor',
  strokeWidth = '0',

  // Prevents svg from being cropped
  style = { overflow: 'visible' },
  ...svgProps
}: SvgWrapperProps) => (
  <div className={clsx('svg-wrapper', rootClassName)}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...{
        className,
        width,
        height,
        viewBox,
        fill,
        opacity,
        stroke,
        strokeWidth,
        style,
      }}
      {...svgProps}
    >
      {children}
    </svg>
  </div>
);
