import React from 'react';
import {
  SvgWrapper,
  SvgWrapperProps,
} from 'components/svgs/SvgWrapper/SvgWrapper';

interface DeviceFilledSvgProps extends Omit<SvgWrapperProps, 'children'> {}

export const DeviceFilledSvg = ({ ...svgProps }: DeviceFilledSvgProps) => (
  <SvgWrapper viewBox="0 0 18 18" {...svgProps}>
    <path d="M10.5 7C11.3284 7 12 6.32843 12 5.5C12 4.67157 11.3284 4 10.5 4C9.67157 4 9 4.67157 9 5.5C9 6.32843 9.67157 7 10.5 7Z" />
    <path d="M13.5 0H7.5C6.12 0 5 1.12 5 2.5V13.5C5 14.88 6.12 16 7.5 16H13.5C14.88 16 16 14.88 16 13.5V2.5C16 1.12 14.88 0 13.5 0ZM8.5 12C8.22 12 8 11.78 8 11.5C8 11.22 8.22 11 8.5 11H12.5C12.78 11 13 11.22 13 11.5C13 11.78 12.78 12 12.5 12H8.5ZM10.5 8C9.12 8 8 6.88 8 5.5C8 4.12 9.12 3 10.5 3C11.88 3 13 4.12 13 5.5C13 6.88 11.88 8 10.5 8Z" />
    <path d="M2 15.5C2 16.88 3.12 18 4.5 18H10.5V18.01C11.17 18.01 11.8 17.75 12.27 17.28C12.47 17.08 12.47 16.77 12.27 16.57C12.07 16.38 11.76 16.37 11.56 16.57C11.28 16.85 10.9 17.01 10.5 17.01H4.5C3.67 17.01 3 16.34 3 15.51V4.51002C3 3.91002 3.36 3.37002 3.92 3.13002C4.18 3.02002 4.3 2.72002 4.19 2.47002C4.08 2.21002 3.79 2.10002 3.53 2.20002C2.6 2.59002 2 3.49002 2 4.50002V15.5Z" />
  </SvgWrapper>
);
