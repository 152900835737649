import { useEffect, useRef, useState, useCallback } from 'react';
import * as Sentry from '@sentry/react';
import { waitForWebsocketConnectionToOpen } from 'utils/waitForWebsocketConnectionToOpen';

import openpathConfig from 'openpathConfig';
import useBroadcastChannel, { BroadcastMessage } from './useBroadcastChannel';

// NOTE: this does not have any unit tests but it is sort of covered by unit tests for useIntercomNotifications
// if you're working on this file specifically in the future, you should probably copy them over and expand

const DEFAULT_SUBSCRIPTION_DATA = [
  {
    hookEventType: '*',
    trigger: {
      $id: 'https://openpath.com/all-events.json',
    },
    clientData: {
      dashboardWidget: 0,
    },
  },
];
const KEEPALIVE_INTERVAL = 9 * 60000; // 9 minutes
const RETRY_STARTING_TIMEOUT = 10000; // 10 seconds
const MAX_RETRIES = 5;

const STATUS = {
  DISCONNECTED: 0,
  CONNECTING: 1,
  CONNECTION_FAILURE: 2,
  SUCCESS: 3,
  FATAL_ERROR: 4, // do not attempt to reconnect
};

type SubscriptionData = {
  hookEventType?: string;
  preFilter?: {
    hookEventType: string;
  };
  trigger: Record<string, unknown>;
  clientData?: Record<string, unknown>;
}[];

interface UseOxygenSocketProps {
  orgId: number | null;
  onConnectionHandler: (socket: WebSocket | null) => void;
  subscriptionData: SubscriptionData;
  clientRequestId: string; // unique string that identifies the socket
  token: string;
  /**
   *  This is temporary for now as to not interfere with the original
   * implementation used in useIntercomNotifications. Once we can confirm
   * https://jira.mot-solutions.com/browse/OPAC-13236 is fixed we can look to migrate
   * it's current usage
   */
  ignoreBroadcastDisconnect?: boolean;
}

const styles = {
  clientRequestId: 'text-decoration: underline;',
  connectionId: 'color: blue; font-weight: bold;',
  none: 'color: initial; font-weight: normal; text-decoration: none',
};

export default ({
  orgId,
  onConnectionHandler,
  subscriptionData = DEFAULT_SUBSCRIPTION_DATA, // see above for shape
  clientRequestId, // unique string that identifies the socket
  token, // required
  ignoreBroadcastDisconnect = false,
}: UseOxygenSocketProps) => {
  const [broadcastChannelRef, broadcastId] = useBroadcastChannel('oxygen');
  const ws = useRef<WebSocket | null>(null);
  const keepAliveTimerRef = useRef<ReturnType<typeof setInterval> | null>(null);
  // using ref instead of state due to rerender issues
  const connectionStatusForRetries = useRef(STATUS.DISCONNECTED);
  const retries = useRef(MAX_RETRIES);
  const retryTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const retryTimeout = useRef(RETRY_STARTING_TIMEOUT);

  const [status, setStatus] = useState(STATUS.DISCONNECTED);

  // we wrap the onConnectionHandler to handle status/error replies from Oxygen
  const _onConnectionHandler = useCallback(
    (pointer: WebSocket | null) => {
      pointer?.addEventListener('message', (event) => {
        if (!event || !event.data) return;

        try {
          const parsed = JSON.parse(event.data);
          const { type, data } = parsed;
          const { statusCode } = data;

          // look for a response to our hello message
          // type === 'command.response' checks that this is a response from Oxygen
          // statusCode === 200 makes sure the request returned successfully
          // data.subscriptionsStatus.numAccepted > 0 makes sure we got at least 1 hookEvent subscription successfully wired
          // data.clientRequestId === clientRequestId allows multiple different clients (eg dashboards, intercom, etc.) to have simultaneous connections to Oxygen without overlap/conflict

          if (type === 'command.response') {
            // eslint-disable-next-line no-console
            console.log(
              `[WS] %c${clientRequestId}%c: ${data.statusCodeDetail}, ` +
                `connectionId: %c${
                  data.connectionId
                }%c at ${new Date().toISOString()} ${JSON.stringify(
                  data.subscriptionsStatus,
                )}`,
              'color: blue;' + styles.clientRequestId,
              styles.none,
              styles.connectionId,
              styles.none,
            );
          }

          if (
            type === 'command.response' &&
            statusCode === 200 &&
            data?.subscriptionsStatus?.numAccepted > 0 &&
            data?.clientRequestId === clientRequestId
          ) {
            // TODO: we should compare data.subscriptionsStatus.numAccepted against subscriptionData.length and console.log an error or something
            setStatus(STATUS.SUCCESS);
          }

          if (statusCode >= 400) {
            Sentry.captureMessage('Websocket error response received:', {
              contexts: {
                debugInfo: {
                  wsInfo: JSON.stringify({
                    url: ws.current?.url,
                    protocol: `oxygen-events-v1|${orgId}|<SIG_REDACTED>`,
                  }),
                  eventData: event.data,
                  subscriptionData: JSON.stringify(subscriptionData),
                  orgId,
                  clientRequestId,
                  statusCode,
                },
              },
            });
          }

          // look for a response that indicates an authentication error
          if (
            type === 'command.response' &&
            [400, 401, 403].includes(statusCode)
          ) {
            connectionStatusForRetries.current = STATUS.FATAL_ERROR;
            setStatus(STATUS.FATAL_ERROR);
          }

          // TODO: ask Jim how we want to handle 5xxs
        } catch (e) {
          console.error('got unhandled error, probably invalid json', e, event);
        }
      });

      if (onConnectionHandler) {
        onConnectionHandler(pointer);
      }
    },
    // Disabling for now as its wants dependencies from the Sentry debugging we are using to try and track down why these error so often
    [onConnectionHandler, clientRequestId], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const connect = useCallback(() => {
    if (
      openpathConfig.OXYGEN_API_URL &&
      token &&
      !ws.current &&
      (orgId || orgId === null)
    ) {
      // authenticate with protocol
      ws.current = new WebSocket(openpathConfig.OXYGEN_API_URL, [
        `oxygen-events-v1|${orgId}|${token}`,
      ]);
      connectionStatusForRetries.current = STATUS.CONNECTING;
      // eslint-disable-next-line no-console
      console.log(
        `[WS] connect called (%c${clientRequestId}%c) at ${new Date().toISOString()}`,
        styles.clientRequestId,
        styles.none,
      );
      // error
      ws.current.onerror = (event) => {
        console.error('WebSocket error observed:', JSON.stringify(event));
        Sentry.captureMessage('Websocket error:', {
          contexts: {
            debugInfo: {
              wsInfo: JSON.stringify({
                url: ws.current?.url,
                protocol: `oxygen-events-v1|${orgId}|<SIG_REDACTED>`,
              }),
              event: JSON.stringify(event),
              subscriptionData: JSON.stringify(subscriptionData),
              orgId,
              clientRequestId,
            },
          },
        });
      };

      // Connection opened
      ws.current.addEventListener('open', async () => {
        try {
          // eslint-disable-next-line no-console
          console.log(
            `[WS] connection opened (%c${clientRequestId}%c) at ${new Date().toISOString()}`,
            styles.clientRequestId,
            styles.none,
          );

          setStatus(STATUS.CONNECTING);
          await waitForWebsocketConnectionToOpen(ws.current);

          // eslint-disable-next-line no-console
          console.log(
            `[WS] Connection ready (%c${clientRequestId}%c) at ${new Date().toISOString()}`,
            styles.clientRequestId,
            styles.none,
          );

          // reset retry settings because opened succcessfully
          connectionStatusForRetries.current = STATUS.CONNECTING;
          if (retryTimerRef.current) {
            clearTimeout(retryTimerRef.current);
            retryTimerRef.current = null;
          }
          retries.current = MAX_RETRIES;
          retryTimeout.current = RETRY_STARTING_TIMEOUT;

          // send subscription data
          ws.current?.send(
            JSON.stringify({
              action: 'replace.subscriptions',
              clientRequestId,
              data: subscriptionData,
            }),
          );

          // start interval
          /** The connection timeout is 10 minutes for idle connection, so we send a keep alive
           * packet every 9 minutes */
          keepAliveTimerRef.current = setInterval(() => {
            if (ws.current && ws.current.readyState === WebSocket.OPEN) {
              try {
                // eslint-disable-next-line no-console
                console.log('[WS] Sending keepalive packet');
                ws.current.send(' ');
              } catch (ex) {
                console.error(ex);
                Sentry.captureMessage('useOxygenSocket keepAlive error:', {
                  contexts: {
                    debugInfo: {
                      error: ex,
                      clientRequestId,
                      orgId,
                      subscriptionData: JSON.stringify(subscriptionData),
                    },
                  },
                });
              }
            }
          }, KEEPALIVE_INTERVAL);

          _onConnectionHandler(ws.current);
        } catch (err) {
          const typeError = err as Error;

          console.warn(`useOxygenSocket: ${typeError.message}`);
          Sentry.captureMessage(
            `useOxygenSocket open event error: ${typeError.message}`,
            {
              contexts: {
                debugInfo: {
                  wsInfo: JSON.stringify({
                    url: ws.current?.url,
                    protocol: `oxygen-events-v1|${orgId}|<SIG_REDACTED>`,
                  }),
                  clientRequestId,
                  orgId,
                  subscriptionData: JSON.stringify(subscriptionData),
                },
              },
            },
          );
        }
      });

      ws.current.addEventListener('close', () => {
        // eslint-disable-next-line no-console
        console.log(
          `[WS] Connection closed (%c${clientRequestId}%c) at ${new Date().toISOString()}`,
          styles.clientRequestId,
          styles.none,
        );

        // kill the keepalive interval
        if (keepAliveTimerRef.current) {
          clearInterval(keepAliveTimerRef.current);
          keepAliveTimerRef.current = null;
        }

        ws.current = null;
        setStatus(STATUS.DISCONNECTED);

        switch (connectionStatusForRetries.current) {
          case STATUS.FATAL_ERROR:
            // eslint-disable-next-line no-console
            console.log(
              `[WS] Closed unexpectedly (%c${clientRequestId}%c) at ${new Date().toISOString()} due to fatal error`,
              styles.clientRequestId,
              styles.none,
            );
            break;
          case STATUS.CONNECTING:
            // Closed without our input - retry to open the connection if there was not a fatal error
            // eslint-disable-next-line no-console
            console.log(
              `[WS] Closed unexpectedly (%c${clientRequestId}%c) at ${new Date().toISOString()} - retrying in ${
                retryTimeout.current / 1000
              } seconds`,
              styles.clientRequestId,
              styles.none,
            );

            if (retries.current > 0) {
              retries.current -= 1;
              retryTimerRef.current = setTimeout(() => {
                connect();
              }, retryTimeout.current);
              // Double the retry timeout for the next retry
              retryTimeout.current *= 2;
            } else {
              retryTimerRef.current = null;
              // eslint-disable-next-line no-console
              console.log(
                `[WS] Retries exhausted - Could not establish a connection with the WebSocket (%c${clientRequestId}%c) at ${new Date().toISOString()}`,
                styles.clientRequestId,
                styles.none,
              );
            }

            break;
          default:
            break;
        }
      });
    } else if (!openpathConfig.OXYGEN_API_URL) {
      // eslint-disable-next-line
      console.debug(
        'OXYGEN_API_URL NOT DEFINED: No Socket Connection will be made.',
      );
    } else {
      console.debug('No Oxygen token was passed'); // eslint-disable-line
    }
  }, [orgId, _onConnectionHandler, subscriptionData, clientRequestId, token]);

  const disconnect = useCallback(() => {
    if (openpathConfig.OXYGEN_API_URL && ws.current) {
      if (ws.current.readyState === WebSocket.OPEN) {
        ws.current.close(1000); // status code 1000 https://www.rfc-editor.org/rfc/rfc6455.html#section-7.4.1

        // reset retry settings because disconnect was called and next time connect is called should behave normally.
        connectionStatusForRetries.current = STATUS.DISCONNECTED;
        if (retryTimerRef.current) {
          clearTimeout(retryTimerRef.current);
          retryTimerRef.current = null;
        }
        retries.current = MAX_RETRIES;
        retryTimeout.current = RETRY_STARTING_TIMEOUT;

        // we don't need to do teardown here (keepalive timer reset, setIsConnected, etc.) because that's all handled in our websocket close handler
      }
    }
  }, []);

  const handleOnline = useCallback(() => {
    if (
      status === STATUS.DISCONNECTED &&
      connectionStatusForRetries.current === STATUS.CONNECTING
    ) {
      // eslint-disable-next-line no-console
      console.log(
        `[WS] handleOnline triggered - (%c${clientRequestId}%c) at ${new Date().toISOString()}`,
      );
      // reset retry code because computer coming online should restart retries.
      if (retryTimerRef.current) {
        clearTimeout(retryTimerRef.current);
        retryTimerRef.current = null;
      }
      retries.current = MAX_RETRIES;
      if (!ws.current) {
        connect();
      }
    }
  }, [status, connect, clientRequestId]);

  // using this ref allows us to only set the online eventlistener once per socket
  const onlineCBRef = useRef(handleOnline);

  useEffect(() => {
    onlineCBRef.current = handleOnline;
  }, [handleOnline]);

  useEffect(() => {
    const onlineCB = () => onlineCBRef.current();
    window.addEventListener('online', onlineCB);

    return () => {
      window.removeEventListener('online', onlineCB);
    };
  }, [clientRequestId]);

  /**
   * Listen to DISCONNECT EVENT (currently * happens on logout)
   */
  useEffect(() => {
    if (!window || !broadcastChannelRef.current) {
      return () => {}; // No-op to always return a function
    }

    const broadcastChannel = broadcastChannelRef.current;

    const handleMessage = (msg: BroadcastMessage) => {
      if (
        [
          msg?.command === 'DISCONNECT',
          msg?.target === '*', // only listen for messages addressed to all
          msg?.sender !== broadcastId,
          !ignoreBroadcastDisconnect,
        ].every(Boolean)
      ) {
        disconnect();
      }
    };

    // listen for commands from other instances (in most cases other tabs)
    broadcastChannel.addEventListener('message', handleMessage);

    return () => {
      broadcastChannel.removeEventListener('message', handleMessage);
    };
  }, [broadcastChannelRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

  // Disconnect from oxygen websocket when the component unmounts
  useEffect(
    () => () => {
      disconnect();
    },
    [disconnect],
  );

  return {
    socket: ws.current,
    isConnected: status === STATUS.SUCCESS,
    connect,
    disconnect,
    status,
  };
};
