/*
 *
 * App reducer
 *
 */

import { fromJS } from 'immutable';
import {
  SET_ORG_USER_PROFILE_IMAGE_URL,
  SET_IDENTITY_INFO,
  SET_UPDATE_REQUIRED,
  SET_DISABLED_ROUTES,
  SET_MAINTENANCE_MODE,
  LOGIN_SUCCESS,
  SET_CURRENT_ORG_NAME,
  SET_CURRENT_ORG,
  APP_ERROR,
  SET_ORG_DROPDOWN_VISIBILITY,
  SET_LOCKDOWN_DROPDOWN_VISIBILITY,
  SET_ALERT,
  CLEAR_ALERT,
  SET_ERROR_MESSAGE,
  SET_CONFIRMATION_PROPS,
  SET_PUSH_NOTIFICATION_IS_ENABLED,
  SET_CURRENT_USER_PREFERENCES,
  SET_PREFETCHED_VIDEOCALL_AUTH,
  RESET_PREFETCHED_VIDEOCALL_AUTH,
} from './constants';

const intercomNotificationsPermission = JSON.parse(
  localStorage.getItem('intercomNotificationsPermission'),
);

// This should be kept in sync with Helium's user preference defaults!
export const initialUserPreferences = {
  videoTimestamp: 'browser',
  preferredMeasurementUnits: 'metric',
  playAlarmNotificationSound: true,
  alarmNotificationSoundVolume: 1.0,
};

export const initialStateObject = fromJS({
  alertStack: [],
  orgDropDownVisible: false,
  lockdownDropDownVisible: false,
  error: null,
  currentIdentity: null,
  currentOrg: null,
  currentParentOrgId: null,
  maintenanceMode: false,
  disabledRoutes: [],
  errorMessage: '',
  errorCta: null,
  forceRefresh: false,
  currentOrgUserImageUrl: null,
  currentIdentityInfo: {},
  loginComplete: false,
  confirmationProps: {
    open: false,
    // header: undefined,
    // content: undefined,
    // cancelButton: undefined,
    // confirmButton: undefined,
    // onCancel: undefined,
    // onConfirm: undefined,
  },
  intercomNotificationsAreEnabled: intercomNotificationsPermission
    ? intercomNotificationsPermission === 'granted'
    : true,
  prefetchedVideocallAuthForDevices: [],
  currentUserPreferences: initialUserPreferences,
});

const initialState = fromJS(initialStateObject);

/**
 * Reducer for App container.
 *
 * @param  {object} state       The current state
 * @param  {string} action       The action to apply to current state
 *
 * @returns {object}           An object containing the new state
 */
function appReducer(state = initialState, action) {
  switch (action.type) {
    case SET_IDENTITY_INFO:
      return state.set('currentIdentityInfo', fromJS(action.info));
    case SET_ORG_USER_PROFILE_IMAGE_URL:
      return state.set('currentOrgUserImageUrl', fromJS(action.imageUrl));
    case SET_UPDATE_REQUIRED:
      return state.set('forceRefresh', action.required);
    case SET_ERROR_MESSAGE:
      return state
        .set('errorMessage', action.message)
        .set('errorCta', action.cta);
    case SET_DISABLED_ROUTES:
      return state.set('disabledRoutes', action.routes);
    case SET_MAINTENANCE_MODE: {
      return state.set('maintenanceMode', action.on);
    }
    case SET_ALERT: {
      const alert = {
        alertType: action.alertType,
        alertIcon: action.alertIcon,
        alertButtons: action.alertButtons,
        alertMessage: action.alertMessage,
        alertHeader: action.alertHeader,
        alertId: action.alertId || state.get('alertStack').size + 1,
        alertTime: action.alertTime,
        timeout: action.timeout
          ? action.timeout
          : action.alertType === 'success'
            ? 5000
            : action.alertType === 'intercom'
              ? 30000
              : null,
      };
      return state.set(
        'alertStack',
        state.get('alertStack').push(fromJS(alert)),
      );
    }
    case CLEAR_ALERT:
      if (action.clearAll) {
        // we want to clear them ALL
        return state.set('alertStack', initialState.get('alertStack'));
      }
      if (action.alertType) {
        // we want to clear ALL alerts of a certain type
        return state.set(
          'alertStack',
          state
            .get('alertStack')
            .filter((alert) => alert.get('alertType') !== action.alertType),
        );
      }
      if (action.id) {
        const index = state
          .get('alertStack')
          .findIndex((alert) => alert.get('alertId') == action.id); // eslint-disable-line eqeqeq
        return index >= 0
          ? state.set('alertStack', state.get('alertStack').remove(index))
          : state;
      }
      return state.set('alertStack', fromJS([]));
    case LOGIN_SUCCESS:
      return state
        .set('error', action.errorMessage || null)
        .set('currentIdentity', action.identityId)
        .set('currentOrg', action.orgId || null)
        .set('currentOrgName', action.orgName || null)
        .set('currentOrgParentId', action.parentOrgId || null)
        .set('isLicenseBased', action.isLicenseBased)
        .set(
          'currentUserPreferences',
          fromJS(action.currentUserPreferences || initialUserPreferences),
        )
        .set('loginComplete', true); // do this last, so that anyone waiting for selectIsLoginComplete() will only see true AFTER currentIdentity, currentOrg, etc. are already set
    case SET_CURRENT_ORG:
      return state.set('currentOrg', action.orgId);
    case SET_CURRENT_ORG_NAME:
      return state.set('currentOrgName', action.orgName);
    case APP_ERROR:
      return state.set('error', action.error);
    case SET_ORG_DROPDOWN_VISIBILITY:
      return state.set('orgDropDownVisible', !state.get('orgDropDownVisible'));
    case SET_LOCKDOWN_DROPDOWN_VISIBILITY:
      return state.set(
        'lockdownDropDownVisible',
        !state.get('lockdownDropDownVisible'),
      );
    case SET_CONFIRMATION_PROPS: {
      return state.set('confirmationProps', fromJS(action.confirmationProps));
    }
    case SET_PUSH_NOTIFICATION_IS_ENABLED:
      return state.set(
        'intercomNotificationsAreEnabled',
        action.intercomNotificationsAreEnabled,
      );
    case SET_CURRENT_USER_PREFERENCES:
      return state.set(
        'currentUserPreferences',
        fromJS(action.currentUserPreferences || initialUserPreferences),
      );
    case SET_PREFETCHED_VIDEOCALL_AUTH: {
      const { opVideoDeviceId, wsUrl, token } = action;
      const currentPrefetchedDevices = state.get(
        'prefetchedVideocallAuthForDevices',
      );

      // Check if we already have auth data stored for this device
      const deviceExists = currentPrefetchedDevices.some(
        (device) => device.opVideoDeviceId === opVideoDeviceId,
      );

      let updatedList;
      if (deviceExists) {
        // Update existing device auth data
        updatedList = currentPrefetchedDevices.map((device) =>
          device.opVideoDeviceId === opVideoDeviceId
            ? {
                ...device,
                wsUrl, // Update WebSocket URL
                token, // Update authentication token
                timeUpdated: Date.now(), // Update timestamp
              }
            : device,
        );
      } else {
        // Add new device auth data
        updatedList = [
          ...currentPrefetchedDevices,
          {
            opVideoDeviceId,
            wsUrl,
            token,
            timeUpdated: Date.now(),
          },
        ];
      }

      // Update state with new auth data list
      return state.set('prefetchedVideocallAuthForDevices', updatedList);
    }
    case RESET_PREFETCHED_VIDEOCALL_AUTH: {
      const { opVideoDeviceId } = action;

      // Get current list of devices with auth data
      const currentPrefetchedDevices = state.get(
        'prefetchedVideocallAuthForDevices',
      );

      // If opVideoDeviceId is provided, remove only that specific device
      // Otherwise, remove all devices by returning an empty array
      let updatedList = [];
      if (opVideoDeviceId) {
        updatedList = currentPrefetchedDevices.filter(
          (device) => device.opVideoDeviceId !== opVideoDeviceId,
        );
      }

      // Update state with filtered list (or empty list if clearing all)
      return state.set('prefetchedVideocallAuthForDevices', updatedList);
    }
    default:
      return state;
  }
}

export default appReducer;
