import React from 'react';
import {
  SvgWrapper,
  SvgWrapperProps,
} from 'components/svgs/SvgWrapper/SvgWrapper';

interface DocumentOutlinedSvgProps extends Omit<SvgWrapperProps, 'children'> {}

export const DocumentOutlinedSvg = ({
  ...svgProps
}: DocumentOutlinedSvgProps) => (
  <SvgWrapper viewBox="0 0 18 18" {...svgProps}>
    <path d="M4 2C3.44772 2 3 2.44772 3 3V15C3 15.5523 3.44772 16 4 16H14C14.5523 16 15 15.5523 15 15V3C15 2.44772 14.5523 2 14 2H4ZM4 1H14C15.1046 1 16 1.89543 16 3V15C16 16.1046 15.1046 17 14 17H4C2.89543 17 2 16.1046 2 15V3C2 1.89543 2.89543 1 4 1ZM5.5 5H12.5C12.7761 5 13 5.22386 13 5.5C13 5.77614 12.7761 6 12.5 6H5.5C5.22386 6 5 5.77614 5 5.5C5 5.22386 5.22386 5 5.5 5ZM5.5 8H12.5C12.7761 8 13 8.22386 13 8.5C13 8.77614 12.7761 9 12.5 9H5.5C5.22386 9 5 8.77614 5 8.5C5 8.22386 5.22386 8 5.5 8ZM5.5 11H9.5C9.77614 11 10 11.2239 10 11.5C10 11.7761 9.77614 12 9.5 12H5.5C5.22386 12 5 11.7761 5 11.5C5 11.2239 5.22386 11 5.5 11Z" />
  </SvgWrapper>
);
