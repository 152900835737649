import { getAccessToken } from 'utils/accessToken';
import { useOpQuery } from 'utils/customHooks/useOpQuery';
import { getActiveScopes } from 'utils/scopes';

export const useGetActiveScopes = (orgId: number) => {
  const accessToken = getAccessToken();

  const { data: orgData, isLoading: isOrgDataLoading } = useOpQuery({
    apiEndpointName: 'describeOrg',
    parameters: [orgId],
    select: (data) => data.json.data,
  });

  const { data: tokenData, isLoading: isAccessTokenDataLoading } = useOpQuery({
    apiEndpointName: 'describeAccessToken',
    parameters: [accessToken],
    select: (data) => data.json.data,
  });

  // Get the active scopes for the current user
  const activeScopes = getActiveScopes({
    tokenScopeList: tokenData?.tokenScopeList ?? [],
    orgId: Number(orgId),
    parentOrgId: orgData?.parentOrg?.id,
  });

  return {
    activeScopes,
    isLoading: isOrgDataLoading || isAccessTokenDataLoading,
  };
};
