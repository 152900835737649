import { useEffect, useRef } from 'react';
import { useLocalStorage } from 'react-use';
import { appColorThemeManager, ColorThemes } from 'utils/appColorThemeManager';
import { THEME } from 'op-themes';
import { useSelector } from 'react-redux';
import { selectCurrentIdentityLanguage } from 'routes/AppContainer/selectors';
import { queryRequest } from 'utils/queryRequest';

export const useThemeObserver = ({ identityId }: { identityId?: number }) => {
  const currentIdentityLanguage = useSelector(selectCurrentIdentityLanguage());
  const observerRef = useRef<MutationObserver | null>(null);

  const [colorTheme, setColorTheme] = useLocalStorage<ColorThemes>(
    appColorThemeManager.localStorageKey,
  );

  useEffect(() => {
    const observerOptions = {
      attributes: true, // Observe changes to attributes (e.g., class)
      attributeFilter: ['class'], // Specify the attributes to observe (class in this case)
    };
    const observer = observerRef.current;

    observer?.disconnect();

    // Create a new MutationObserver
    observerRef.current = new MutationObserver(() => {
      const newColorTheme = appColorThemeManager.getCurrentElColorTheme();
      // Update the state only if the class state has changed
      if (colorTheme !== newColorTheme) {
        setColorTheme(newColorTheme);
      }
    });

    // Start observing the target node (body element) with the specified options
    observerRef.current.observe(appColorThemeManager.baseEl, observerOptions);

    // Clean up the observer when the component unmounts
    return () => {
      observer?.disconnect();
    };
  }, [colorTheme, setColorTheme]);

  // Sync dark mode - will mostly come into play when someone logs in on a new device
  useEffect(() => {
    const getIdentityPreferences = async () => {
      /**
       * We cannot use useOpQuery as there is no guarantee this usage happens in a
       * QueryProvider. We cant move the Provider up a level until we don't need
       * `dispatch(setAlert('error', error.message));`
       */
      const {
        json: { data: identityPreferences },
      } = await queryRequest({
        apiEndpointName: 'describeIdentityPreferenceSet',
        parameters: [identityId!],
        currentIdentityLanguage,
      });

      // Keep local storage in sync
      if (
        colorTheme !== identityPreferences?.colorTheme &&
        identityPreferences?.colorTheme
      ) {
        appColorThemeManager.handleThemeToggle(identityPreferences.colorTheme);
        setColorTheme(identityPreferences.colorTheme);
      }
    };

    if (identityId) {
      getIdentityPreferences();
    }
  }, [identityId]); // eslint-disable-line react-hooks/exhaustive-deps

  // Add or remove "dark" class on body
  useEffect(() => {
    if (colorTheme) {
      appColorThemeManager.handleThemeToggle(colorTheme);
    }

    if (colorTheme === 'system') {
      appColorThemeManager.initSystemThemeChangeListener();
    } else {
      appColorThemeManager.removeSystemThemeChangeListener();
    }
  }, [colorTheme]);

  const themeName =
    colorTheme && appColorThemeManager.getColorThemeForProvider(colorTheme);

  const theme = themeName === 'dark' ? THEME.dark : THEME.light;

  return { theme, themeName };
};
