import { setLocalSetting, getLocalSetting } from 'utils/localUserSettings';

// @DEPRECATED DO NOT USE
export const setHardwareDashboardOption = (key, value) =>
  setLocalSetting('op-hardwareDashboard', key, value);
export const getHardwareDashboardOption = (key, defaultReturn = null) =>
  getLocalSetting('op-hardwareDashboard', key, defaultReturn, true);

export const setDashboardOption = (key, value, orgId = null) => {
  const current = getLocalSetting('op-dashboardOptions', key, {});
  const v = {
    ...current,
    [orgId]: value,
  };
  setLocalSetting('op-dashboardOptions', key, v);
};
export const getDashboardOption = (key, defaultReturn = null, orgId) => {
  // get the local settings
  const ls = getLocalSetting('op-dashboardOptions', key, defaultReturn);
  return ls[orgId] || defaultReturn;
};

export const findLatestTimestamp = (metadata) => {
  if (!metadata) return null;
  if (!Number.isNaN(Number(metadata.timestamp))) return metadata.timestamp;
  return Object.entries(metadata).reduce((ts, [, v]) => {
    const vts = findLatestTimestamp(v);
    return vts > ts ? vts : ts;
  }, 0);
};

export const findLatestPublishedTimestamp = (data) => {
  // If the data is null, undefined, or not an object, return 0
  if (data === 'undefined' || data === null || typeof data !== 'object') {
    return 0;
  }

  // If data contains a publishedTimestamp that is a number, return it
  if (Number(data?.publishedTimestamp)) {
    return data.publishedTimestamp;
  }

  /** Otherwise traverse the values of the object and through recursion
   * return back the largest publishedTimestamp */
  return Object.values(data).reduce((timestamp, value) => {
    const valueTimestamp = findLatestPublishedTimestamp(value);
    return Math.max(valueTimestamp, timestamp);
  }, 0);
};
