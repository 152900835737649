import React from 'react';
import {
  SvgWrapper,
  SvgWrapperProps,
} from 'components/svgs/SvgWrapper/SvgWrapper';

interface AdminOutlinedSvgProps extends Omit<SvgWrapperProps, 'children'> {}

export const AdminOutlinedSvg = ({ ...svgProps }: AdminOutlinedSvgProps) => (
  <SvgWrapper viewBox="0 0 19 18" {...svgProps}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3396 8.998C10.9346 9.28281 10.5699 9.62087 10.2554 10.002H4.502C2.573 10.002 1.004 11.571 1.004 13.5V14.998H9.2065C9.30539 15.3481 9.43807 15.684 9.6008 16.002H0.502C0.225 16.002 0 15.777 0 15.5V13.5C0 11.018 2.02 8.998 4.502 8.998H5.027C3.805 8.086 3.002 6.642 3.002 5C3.002 2.239 5.241 0 8.002 0C10.763 0 13.002 2.239 13.002 5C13.002 6.642 12.199 8.086 10.977 8.998H11.3396ZM4.002 5C4.002 2.794 5.796 1 8.002 1C10.208 1 12.002 2.794 12.002 5C12.002 7.199 10.218 8.987 8.021 8.998H7.982C5.786 8.987 4.002 7.199 4.002 5Z"
    />
    <path d="M17.7262 14.5449L18.3122 14.8839C18.5522 15.0219 18.6332 15.3279 18.4952 15.5669L17.9952 16.4329C17.8572 16.6719 17.5522 16.7539 17.3122 16.6159L16.7182 16.2729C16.4432 16.5079 16.1292 16.6929 15.7812 16.8159V17.4999C15.7812 17.7759 15.5572 17.9999 15.2812 17.9999H14.2812C14.0052 17.9999 13.7812 17.7759 13.7812 17.4999V16.8159C13.4332 16.6929 13.1192 16.5079 12.8442 16.2729L12.2502 16.6159C12.0102 16.7539 11.7052 16.6719 11.5672 16.4329L11.0672 15.5669C10.9292 15.3279 11.0102 15.0219 11.2502 14.8839L11.8362 14.5449C11.8032 14.3679 11.7812 14.1869 11.7812 13.9999C11.7812 13.8129 11.8032 13.6319 11.8362 13.4549L11.2502 13.1159C11.0102 12.9779 10.9292 12.6719 11.0672 12.4329L11.5672 11.5669C11.7052 11.3279 12.0102 11.2459 12.2502 11.3839L12.8442 11.7269C13.1192 11.4919 13.4332 11.3069 13.7812 11.1839V10.4999C13.7812 10.2239 14.0052 9.99988 14.2812 9.99988H15.2812C15.5572 9.99988 15.7812 10.2239 15.7812 10.4999V11.1839C16.1292 11.3069 16.4432 11.4919 16.7182 11.7269L17.3122 11.3839C17.5522 11.2459 17.8572 11.3279 17.9952 11.5669L18.4952 12.4329C18.6332 12.6719 18.5522 12.9779 18.3122 13.1159L17.7262 13.4549C17.7592 13.6319 17.7812 13.8129 17.7812 13.9999C17.7812 14.1869 17.7592 14.3679 17.7262 14.5449ZM13.7812 13.9999C13.7812 14.5519 14.2292 14.9999 14.7812 14.9999C15.3332 14.9999 15.7812 14.5519 15.7812 13.9999C15.7812 13.4479 15.3332 12.9999 14.7812 12.9999C14.2292 12.9999 13.7812 13.4479 13.7812 13.9999Z" />
  </SvgWrapper>
);
