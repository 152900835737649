import { DefaultError, QueryCache, QueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAlert } from 'routes/AppContainer/actions';

export const useCreateQueryClient = () => {
  const dispatch = useDispatch();

  const [queryClient] = useState(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          // Catch any react-query useQuery error globally and display the alert (this will not catch useMutation errors)
          // @ts-expect-error TS doesnt like the extra field we pass to the error
          onError: (
            error: DefaultError & { suppressErrorMessage: boolean },
          ) => {
            if (!error.suppressErrorMessage) {
              dispatch(setAlert('error', error.message));
            }
          },
        }),
      }),
  );

  return queryClient;
};
