import { all, put, takeEvery, call, select } from 'redux-saga/effects';
import { listLockdownPlans } from 'heliumApi';
import {
  selectCurrentOrgId,
  selectCurrentUserId,
} from 'global/accessToken/selectors';
import { selectFirstValidCloudKeyId } from 'global/cloudKey/selectors';
import { sendToHelium } from 'utils/helpers';
import { setLockdownPlans } from './actions';
import {
  REQUEST_LIST_LOCKDOWN_PLANS,
  TRIGGER_LOCKDOWN_PLAN,
  REVERT_LOCKDOWN_PLAN,
} from './constants';

export function* requestListLockdownPlans() {
  const currentOrgId = yield select(selectCurrentOrgId());
  if (!currentOrgId) return;
  const lockdownPlans = yield call(listLockdownPlans, currentOrgId, {
    limit: 50,
  });
  if (!lockdownPlans.err) {
    yield put(setLockdownPlans(lockdownPlans.data.data));
  }
}

export function* requestTriggerLockdownPlan(action) {
  const currentOrgId = yield select(selectCurrentOrgId());
  const currentUserId = yield select(selectCurrentUserId());
  const credentialId = yield select(selectFirstValidCloudKeyId());
  yield call(
    sendToHelium,
    'cloudKeyTriggerLockdownPlan',
    [currentOrgId, currentUserId, credentialId],
    {
      lockdownPlanId: `${action.lockdownPlanId}`,
      description: 'Lockdown Triggered via Access',
    },
    { successMessage: 'Your lockdown has been initiated!' },
  );
  yield call(requestListLockdownPlans);
}

export function* requestRevertLockdownPlan(action) {
  const currentOrgId = yield select(selectCurrentOrgId());
  const currentUserId = yield select(selectCurrentUserId());
  const credentialId = yield select(selectFirstValidCloudKeyId());
  yield call(
    sendToHelium,
    'cloudKeyRevertLockdownPlan',
    [currentOrgId, currentUserId, credentialId],
    {
      lockdownPlanId: `${action.lockdownPlanId}`,
      description: 'Lockdown Reverted via Access',
    },
    { successMessage: 'Your lockdown has been reverted!' },
  );
  yield call(requestListLockdownPlans);
}

function* rootSaga() {
  yield all([
    takeEvery(REQUEST_LIST_LOCKDOWN_PLANS, requestListLockdownPlans),
    takeEvery(TRIGGER_LOCKDOWN_PLAN, requestTriggerLockdownPlan),
    takeEvery(REVERT_LOCKDOWN_PLAN, requestRevertLockdownPlan),
  ]);
}

export default rootSaga;
