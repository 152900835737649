import { ReactNode } from 'react';

// Using the release notes to determine the alertCount on the Release Notes menu item
import releaseNotesJson from 'routes/ReleaseNotesPage/releaseNotes.json';
import masterReleaseNotesJson from 'routes/MasterReleaseNotesPage/masterReleaseNotes.js';
import Badge from 'antd/es/badge';
import { UserFilledSvg } from 'components/svgs/UserFilledSvg/UserFilledSvg';
import { CameraOutlinedSvg } from 'components/svgs/CameraOutlinedSvg/CameraOutlinedSvg';
import { HomeFilledSvg } from 'components/svgs/HomeFilledSvg/HomeFilledSvg';
import { HomeOutlinedSvg } from 'components/svgs/HomeOutlinedSvg/HomeOutlinedSvg';
import { CameraFilledSvg } from 'components/svgs/CameraFilledSvg/CameraFilledSvg';
import { UserOutlinedSvg } from 'components/svgs/UserOutlinedSvg/UserOutlinedSvg';
import { LocationOutlinedSvg } from 'components/svgs/LocationOutlinedSvg/LocationOutlinedSvg';
import { LocationFilledSvg } from 'components/svgs/LocationFilledSvg/LocationFilledSvg';
import { DevicesOutlinedSvg } from 'components/svgs/DevicesOutlinedSvg/DevicesOutlinedSvg';
import { DeviceFilledSvg } from 'components/svgs/DeviceFilledSvg/DeviceFilledSvg';
import { PuzzleOutlinedSvg } from 'components/svgs/PuzzleOutlinedSvg/PuzzleOutlinedSvg';
import { PuzzleFilledSvg } from 'components/svgs/PuzzleFilledSvg/PuzzleFilledSvg';
import { SettingOutlinedSvg } from 'components/svgs/SettingOutlinedSvg/SettingOutlinedSvg';
import { SettingFilledSvg } from 'components/svgs/SettingFilledSvg/SettingFilledSvg';
import { AdminFilledSvg } from 'components/svgs/AdminFilledSvg/AdminFilledSvg';
import { QuestionMarkOutlinedSvg } from 'components/svgs/QuestionMarkOutlinedSvg/QuestionMarkOutlinedSvg';
import { QuestionMarkFilledSvg } from 'components/svgs/QuestionMarkFilledSvg/QuestionMarkFilledSvg';
import { DocumentOutlinedSvg } from 'components/svgs/DocumentOutlinedSvg/DocumentOutlinedSvg';
import { DocumentFilledSvg } from 'components/svgs/DocumentFilledSvg/DocumentFilledSvg';
import { FileOutlinedSvg } from 'components/svgs/FileOutlinedSvg/FileOutlinedSvg';
import { FileFilledSvg } from 'components/svgs/FileFilledSvg/FileFilledSvg';
import { AdminOutlinedSvg } from 'components/svgs/AdminOutlinedSvg/AdminOutlinedSvg';
import { t } from 'i18next';
import { UserGroupOutlineSvg } from 'components/svgs/UserGroupOutlineSvg/UserGroupOutlineSvg';
import { UserGroupFilledSvg } from 'components/svgs/UserGroupFilledSvg/UserGroupFilledSvg';
import { SparkIcon } from 'new-components/svgs/SparkIcon';
import {
  appMarketplaceRoute,
  alarmDashboardRoute,
  errorMessageRoute,
  hardwareDashboardRoute,
  entryDashboardRoute,
  buildingsRoute,
  buildingsCreateRoute,
  buildingsEditRoute,
  licensesRoute,
  usersTableRoute,
  userSchedulesTableRoute,
  userSchedulesCreateRoute,
  userSchedulesEditRoute,
  userCustomFieldsTableRoute,
  userCustomFieldsCreateRoute,
  userCustomFieldsEditRoute,
  userCredentialsManagementRoute,
  presenceReportRoute,
  usersImportRoute,
  rolesRoute,
  sitesTableRoute,
  sitesCreateRoute,
  sitesEditRoute,
  zonesTableRoute,
  zonesCreateRoute,
  zonesEditDetailsRoute,
  zonesEditAntiPassbackRoute,
  entriesTableRoute,
  entryStatesTableRoute,
  entryStatesCreateRoute,
  entryStatesEditRoute,
  entrySchedulesTableRoute,
  entrySchedulesCreateRoute,
  entrySchedulesEditRoute,
  acusTableRoute,
  acusCreateRoute,
  acusPortsRoute,
  acusEditRoute,
  readersTableRoute,
  readersCreateRoute,
  readersEditRoute,
  allegionReadersTableRoute,
  thirdPartyReadersEditRoute,
  activityLogsRoute,
  activityInsightsRoute,
  userActivityRoute,
  entryActivityRoute,
  userActivitySummaryRoute,
  entryActivitySummaryRoute,
  entryAccessAuditRoute,
  userAccessAuditRoute,
  portalAuditReportRoute,
  integrationsRoute,
  integrationsIdentityProviderGsuite,
  integrationsIdentityProviderAzure,
  integrationsIdentityProviderOkta,
  integrationsIdentityProviderOnelogin,
  mobileAppSettingsRoute,
  mobileAppBadgeUiRoute,
  badgeTemplatesTablePageRoute,
  intercomUserDirectoryRoute,
  quickStartRoute,
  accountPageRoute,
  releaseNotesRoute,
  masterDashboardRoute,
  masterOrgTableRoute,
  masterOrgEditRoute,
  masterOrgEditPackageRoute,
  masterOrgEditFeatureFlagRoute,
  masterOrgCreateRoute,
  masterIdentitiesTableRoute,
  masterIdentitiesEditRoute,
  masterIdentitiesEditSecurityRoute,
  masterIdentitiesCreateRoute,
  masterRolesTableRoute,
  masterRolesEditRoute,
  masterRolesCreateRoute,
  masterAcusTableRoute,
  masterAcusEditRoute,
  masterEntriesTableRoute,
  masterThirdPartyDevicesRoute,
  masterAllegionFobsRoute,
  masterFeatureFlagsTableRoute,
  masterFeatureFlagsEditRoute,
  masterFeatureFlagsCreateRoute,
  masterPortalAuditReportRoute,
  masterAcuSoftwareReportRoute,
  myAppsTableRoute,
  masterReleaseNotesRoute,
  alertSettingsRoute,
  lockdownTableRoute,
  camerasRoute,
  cameraDetailsRoute,
  lockdownCreateRoute,
  lockdownEditRoute,
  lockdownEditConfigRoute,
  requiredTermsRoute,
  subscriptionsTableRoute,
  subscriptionsCreateRoute,
  subscriptionsEditRoute,
  visualActivityReportRoute,
  wirelessLockGatewaysTableRoute,
  wirelessLocksTableRoute,
  allegionWirelessLocksTableRoute,
  wirelessLocksEditRoute,
  deviceUpdateManagement,
  customDashboardsRoute,
  scheduledReportsRoute,
  scheduledReportsCreateRoute,
  scheduledReportsEditRoute,
  cameraDeeplinkRedirectRoute,
  alarmEditRoute,
  alarmReportRoute,
  musterReportsRoute,
  accessGroupsRoute,
  activityDashboardRoute,
  videoIntercomReadersRoute,
  videoReadersRoute,
  occupancyReportRoute,
  alarmConfigurationsRoute,
  partnerDashboardRoute,
  partnerStoreRoute,
  partnerOrgTableRoute,
  partnerOrgCreateRoute,
  partnerOrgEditRoute,
  partnerOrgEditPackageRoute,
  partnerSubscriptionRenewalsTableRoute,
  partnerRequestsTableRoute,
  partnerAcusTableRoute,
  partnerAcusEditRoute,
  partnerThirdPartyDevicesRoute,
  partnerEntriesTableRoute,
  partnerBillingRoute,
  dashboardContainerRoute,
  mapsDashboardRoute,
  chatRoute,
} from '../routes/constants';

export interface OpRawMenuItemType {
  key: string;
  title: string;
  route?: string | null;
  scope?: string[];
  icon?: ReactNode;
  selectedIcon?: ReactNode;
  hidden?: boolean;
  items?: OpRawMenuItemType[];
  external?: boolean;
  admin?: boolean;
  requiredFeatureCode?: string | string[];
  action?: string;
  type?: 'group';
}

export const createRawMenuItems = (
  mergedOpenpathConfig: Record<string, any> = {},
  { isLicenseBased, hasAppMarketplace, billingMode, isUnified } = {
    isLicenseBased: false,
    hasAppMarketplace: false,
    billingMode: '',
    isUnified: false,
  },
) => {
  const seenReleaseNotesCount = localStorage.getItem('release-notes-count');
  const seenMasterReleaseNotesCount = localStorage.getItem(
    'master-release-notes-count',
  );

  const newReleaseNotesCount =
    releaseNotesJson.length - Number(seenReleaseNotesCount);
  const newMasterReleaseNotesCount =
    masterReleaseNotesJson().length - Number(seenMasterReleaseNotesCount);

  return [
    {
      key: dashboardContainerRoute,
      title: t('Home'),
      route: dashboardContainerRoute,
      scope: [
        'o{orgId}-dash:r',
        'o{orgId}-dash:w',
        'o{orgId}-dashActivity:r',
        'o{orgId}-dashActivity:w',
        'o{orgId}-dashAlarm:r',
        'o{orgId}-dashAlarm:w',
        'o{orgId}-dashEntry:r',
        'o{orgId}-dashEntry:w',
        'o{orgId}-hw:r',
        'o{orgId}-hw:w',
        'o:w',
        'o:r',
        's-o:r',
        's-o:w',
      ],
      icon: <HomeOutlinedSvg />,
      selectedIcon: <HomeFilledSvg />,
      items: [
        {
          key: activityDashboardRoute,
          title: t('Activity dashboard'),
          route: activityDashboardRoute,
          scope: [
            'o{orgId}-dash:r',
            'o{orgId}-dash:w',
            'o{orgId}-dashActivity:r',
            'o{orgId}-dashActivity:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
          ],
          hidden: true,
        },
        {
          key: alarmDashboardRoute,
          title: t('Alarms dashboard'),
          route: alarmDashboardRoute,
          scope: [
            'o{orgId}-dash:r',
            'o{orgId}-dash:w',
            'o{orgId}-dashAlarm:r',
            'o{orgId}-dashAlarm:w',
            'o:w',
            'o:r',
          ],
          hidden: true,
        },
        {
          key: entryDashboardRoute,
          title: t('Entry dashboard'),
          route: entryDashboardRoute,
          scope: [
            'o{orgId}-dash:r',
            'o{orgId}-dash:w',
            'o{orgId}-dashEntry:r',
            'o{orgId}-dashEntry:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
          ],
          hidden: true,
        },
        {
          key: hardwareDashboardRoute,
          title: t('Device dashboard'),
          route: hardwareDashboardRoute,
          scope: [
            'o{orgId}-dash:r',
            'o{orgId}-dash:w',
            'o{orgId}-hw:r',
            'o{orgId}-hw:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
          ],
          hidden: true,
        },
        {
          key: mapsDashboardRoute,
          title: t('Maps'),
          route: mapsDashboardRoute,
          scope: [
            'o{orgId}-map:r',
            'o{orgId}-map:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
          ],
          hidden: true,
        },
        {
          key: customDashboardsRoute,
          title: t('Custom dashboard'),
          route: customDashboardsRoute,
          scope: [
            'o{orgId}-dash:r',
            'o{orgId}-dash:w',
            'o{orgId}-hw:r',
            'o{orgId}-hw:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
          ],
          hidden: true,
        },
      ],
    },
    // TODO - Remove this once we no longer have partner center in CC (playing it safe)
    {
      key: 'partner-center',
      title: t('Partners'),
      icon: <UserGroupOutlineSvg />,
      selectedIcon: <UserGroupFilledSvg />,
      scope: [
        'o{orgId}-erpParentStore:w',
        'o{orgId}-erpParentCenter:w',
        'o:w',
        'o:r',
        's-o:r',
        's-o:w',
      ],
      requiredFeatureCode: 'subOrgs',
      items: [
        {
          key: partnerDashboardRoute,
          title: t('Dashboard'),
          route: partnerDashboardRoute,
          scope: ['o:w', 'o:r', 's-o:r', 's-o:w'],
          requiredFeatureCode: 'subOrgs',
        },
        {
          key: partnerStoreRoute,
          title: t('Store'),
          route: partnerStoreRoute,
          scope: ['o{orgId}-erpParentStore:w', 'o:w', 'o:r'], // no s-o scopes here since users specifically need the erpParentStore permission here
          requiredFeatureCode: 'subOrgs',
        },
        {
          key: partnerOrgTableRoute,
          title: t('Customers'),
          route: partnerOrgTableRoute,
          scope: ['o:r', 'o:w', 's-o:r', 's-o:w'],
          requiredFeatureCode: 'subOrgs',

          items: [
            {
              key: partnerOrgCreateRoute,
              title: t('Create organization'),
              route: partnerOrgCreateRoute,
              scope: ['o:r', 'o:w', 's-o:r', 's-o:w'],
              requiredFeatureCode: 'subOrgs',
              hidden: true,
            },
            {
              key: partnerOrgEditRoute,
              title: t('Edit organization'),
              route: partnerOrgEditRoute,
              scope: ['o:r', 'o:w', 's-o:r', 's-o:w'],
              requiredFeatureCode: 'subOrgs',
              hidden: true,
            },
            {
              key: partnerOrgEditPackageRoute,
              title: t('Edit organization package'),
              route: partnerOrgEditPackageRoute,
              scope: ['o:r', 'o:w', 's-o:r', 's-o:w'],
              requiredFeatureCode: 'subOrgs',
              hidden: true,
            },
          ],
        },
        {
          key: partnerSubscriptionRenewalsTableRoute,
          title: t('Renewals'),
          route: partnerSubscriptionRenewalsTableRoute,
          scope: ['o{orgId}-erpParentStore:w', 'o:w', 'o:r'], // no s-o scopes here since users specifically need the erpParentStore permission here
          requiredFeatureCode: 'subOrgs',
        },
        {
          key: partnerRequestsTableRoute,
          title: t('License requests'),
          route: partnerRequestsTableRoute,
          scope: ['o:w', 'o:r', 's-o:r', 's-o:w'],
          requiredFeatureCode: 'subOrgs',
        },
        {
          key: partnerAcusTableRoute,
          title: t('ACUs'),
          route: partnerAcusTableRoute,
          scope: ['o:r', 'o:w', 's-o:r', 's-o:w'],
          requiredFeatureCode: 'subOrgs',
          items: [
            {
              key: partnerAcusEditRoute,
              title: t('ACUs'),
              route: partnerAcusEditRoute,
              scope: ['o:r', 'o:w', 's-o:r', 's-o:w'],
              requiredFeatureCode: 'subOrgs',
              hidden: true,
            },
          ],
        },
        {
          key: partnerThirdPartyDevicesRoute,
          title: t('3rd-party devices'),
          route: partnerThirdPartyDevicesRoute,
          scope: ['o:r', 'o:w', 's-o:r', 's-o:w'],
          requiredFeatureCode: 'subOrgs',
        },
        {
          key: partnerEntriesTableRoute,
          title: t('Entries'),
          route: partnerEntriesTableRoute,
          scope: ['o:r', 'o:w', 's-o:r', 's-o:w'],
          requiredFeatureCode: 'subOrgs',
        },
        {
          key: partnerBillingRoute,
          title: t('Billing'),
          route: partnerBillingRoute,
          scope: ['o{orgId}-erpParentCenter:w', 'o:w', 'o:r'], // no s-o scopes here since users specifically need the erpParentStore permission here
          requiredFeatureCode: 'subOrgs',
        },
        {
          key: 'partner-marketing',
          title: t('Marketing'),
          route: 'https://loginvsa.motorolasolutions.com/',
          scope: ['o:r', 'o:w', 's-o:r', 's-o:w'],
          requiredFeatureCode: 'subOrgs',
          external: true,
        },
        {
          key: 'https://vsatraining.myabsorb.com/',
          title: t('Training'),
          route: 'https://vsatraining.myabsorb.com/',
          scope: ['o:r', 'o:w', 's-o:r', 's-o:w'],
          requiredFeatureCode: 'subOrgs',
          external: true,
        },
      ],
      hidden: isUnified,
    },
    {
      key: camerasRoute,
      title: t('Cameras'),
      route: camerasRoute,
      icon: <CameraOutlinedSvg />,
      selectedIcon: <CameraFilledSvg />,
      scope: [
        'o:w',
        's-o:w',
        'o{orgId}-videoProviderLive:w', // they can see because they have "play back videoProvider live" scope
        'o{orgId}-videoProviderPlayback:w', // they can see because they have "play back videoProvider clips" scope
        'o{orgId}-opvideoDevice:w', // they can see it because they have "fine tune video settings" scope
        'o-supportVideo:w', // they can see it because they have support scope
      ],
      items: [
        {
          key: cameraDetailsRoute,
          title: t('Camera details'),
          route: cameraDetailsRoute,
          scope: [
            'o:w',
            's-o:w',
            'o{orgId}-videoProviderLive:w', // they can see because they have "play back videoProvider live" scope
            'o{orgId}-videoProviderPlayback:w', // they can see because they have "play back videoProvider clips" scope
            'o{orgId}-opvideoDevice:w', // they can see it because they have "fine tune video settings" scope
            'o-supportVideo:w', // they can see it because they have support scope
          ],
          hidden: true,
        },
        {
          key: cameraDeeplinkRedirectRoute,
          title: t('Camera deeplink redirect'),
          route: cameraDeeplinkRedirectRoute,
          scope: [
            'o:w',
            's-o:w',
            'o{orgId}-videoProviderLive:w', // they can see because they have "play back videoProvider live" scope
            'o{orgId}-videoProviderPlayback:w', // they can see because they have "play back videoProvider clips" scope
            'o{orgId}-opvideoDevice:w', // they can see it because they have "fine tune video settings" scope
            'o-supportVideo:w', // they can see it because they have support scope
          ],
          hidden: true,
        },
      ],
    },

    {
      key: 'primary-users',
      title: t('Users'),
      icon: <UserOutlinedSvg />,
      selectedIcon: <UserFilledSvg />,
      scope: [
        'o{orgId}-user:r',
        'o{orgId}-user:w',
        'o:w',
        'o:r',
        's-o:r',
        's-o:w',
        's-o-video:r',
        's-o-video:w',
        'o-user:r',
        'o-user:w',
      ],
      items: [
        {
          key: usersTableRoute,
          title: t('Users'),
          route: usersTableRoute,
          scope: [
            'o{orgId}-user:r',
            'o{orgId}-user:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
            's-o-video:r',
            's-o-video:w',
            'o-user:r',
            'o-user:w',
          ],
        },
        {
          key: usersImportRoute,
          title: t('Import'),
          route: usersImportRoute,
          scope: [
            'o{orgId}-user:r',
            'o{orgId}-user:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
            's-o-video:r',
            's-o-video:w',
          ],
        },
        {
          key: accessGroupsRoute,
          title: t('Access groups'),
          route: accessGroupsRoute,
          scope: [
            'o{orgId}-user:r',
            'o{orgId}-user:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
            's-o-video:r',
            's-o-video:w',
            'o-user:r',
            'o-user:w',
          ],
        },
        {
          key: rolesRoute,
          title: t('Roles'),
          route: rolesRoute,
          scope: [
            'o{orgId}-role:r',
            'o{orgId}-role:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
            's-o-video:r',
            's-o-video:w',
            'o-role:r',
            'o-role:w',
          ],
        },
        {
          key: userSchedulesTableRoute,
          title: t('Schedules'),
          route: userSchedulesTableRoute,
          scope: [
            'o{orgId}-user:r',
            'o{orgId}-user:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
          ],
          items: [
            {
              key: userSchedulesCreateRoute,
              title: t('Create schedule'),
              route: userSchedulesCreateRoute,
              scope: [
                'o{orgId}-user:r',
                'o{orgId}-user:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
              hidden: true,
            },
            {
              key: userSchedulesEditRoute,
              title: t('Edit schedule'),
              route: userSchedulesEditRoute,
              scope: [
                'o{orgId}-user:r',
                'o{orgId}-user:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
              hidden: true,
            },
          ],
        },
        {
          key: userCustomFieldsTableRoute,
          title: t('Custom fields'),
          route: userCustomFieldsTableRoute,
          scope: [
            'o{orgId}-user:r',
            'o{orgId}-user:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
          ],
          items: [
            {
              key: userCustomFieldsCreateRoute,
              title: t('Create schedule'),
              route: userCustomFieldsCreateRoute,
              scope: [
                'o{orgId}-user:r',
                'o{orgId}-user:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
              hidden: true,
            },
            {
              key: userCustomFieldsEditRoute,
              title: t('Edit schedule'),
              route: userCustomFieldsEditRoute,
              scope: [
                'o{orgId}-user:r',
                'o{orgId}-user:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
              hidden: true,
            },
          ],
        },
      ],
    },

    {
      key: 'primary-sites',
      title: t('Sites'),
      icon: <LocationOutlinedSvg />,
      selectedIcon: <LocationFilledSvg />,
      scope: [
        'o{orgId}-site:r',
        'o{orgId}-site:w',
        'o{orgId}-siteGeneral:r',
        'o{orgId}-siteGeneral:w',
        'o:w',
        'o:r',
        's-o:r',
        's-o:w',
      ],
      items: [
        {
          key: sitesTableRoute,
          title: t('Sites'),
          route: sitesTableRoute,
          scope: [
            'o{orgId}-site:r',
            'o{orgId}-site:w',
            'o{orgId}-siteGeneral:r',
            'o{orgId}-siteGeneral:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
          ],
          items: [
            {
              key: sitesCreateRoute,
              title: t('Create site'),
              route: sitesCreateRoute,
              scope: [
                'o{orgId}-site:r',
                'o{orgId}-site:w',
                'o{orgId}-siteGeneral:r',
                'o{orgId}-siteGeneral:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
              hidden: true,
            },
            {
              key: sitesEditRoute,
              title: t('Edit site'),
              route: sitesEditRoute,
              scope: [
                'o{orgId}-site:r',
                'o{orgId}-site:w',
                'o{orgId}-siteGeneral:r',
                'o{orgId}-siteGeneral:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
              hidden: true,
            },
          ],
        },
        {
          key: buildingsRoute,
          title: t('Buildings'),
          route: buildingsRoute,
          scope: [
            'o{orgId}-site:r',
            'o{orgId}-site:w',
            'o{orgId}-building:r',
            'o{orgId}-building:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
          ],
          items: [
            {
              key: buildingsCreateRoute,
              title: t('Create building'),
              route: buildingsCreateRoute,
              scope: [
                'o{orgId}-site:r',
                'o{orgId}-site:w',
                'o{orgId}-building:r',
                'o{orgId}-building:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
              hidden: true,
            },
            {
              key: buildingsEditRoute,
              title: t('Edit building'),
              route: buildingsEditRoute,
              scope: [
                'o{orgId}-site:r',
                'o{orgId}-site:w',
                'o{orgId}-building:r',
                'o{orgId}-building:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
              hidden: true,
            },
          ],
        },
        {
          key: zonesTableRoute,
          title: t('Zones'),
          route: zonesTableRoute,
          scope: [
            'o{orgId}-site:r',
            'o{orgId}-site:w',
            'o{orgId}-siteGeneral:r',
            'o{orgId}-siteGeneral:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
          ],
          items: [
            {
              key: zonesCreateRoute,
              title: t('Create zone'),
              route: zonesCreateRoute,
              scope: [
                'o{orgId}-site:r',
                'o{orgId}-site:w',
                'o{orgId}-siteGeneral:r',
                'o{orgId}-siteGeneral:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
              hidden: true,
            },
            {
              key: zonesEditDetailsRoute,
              title: t('Edit zone'),
              route: zonesEditDetailsRoute,
              scope: [
                'o{orgId}-site:r',
                'o{orgId}-site:w',
                'o{orgId}-siteGeneral:r',
                'o{orgId}-siteGeneral:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
              hidden: true,
            },
            {
              key: zonesEditAntiPassbackRoute,
              title: t('Edit zone'),
              route: zonesEditAntiPassbackRoute,
              scope: [
                'o{orgId}-site:r',
                'o{orgId}-site:w',
                'o{orgId}-siteGeneral:r',
                'o{orgId}-siteGeneral:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
              hidden: true,
            },
          ],
        },
        {
          key: entriesTableRoute,
          title: t('Entries'),
          route: entriesTableRoute,
          scope: [
            'o{orgId}-site:r',
            'o{orgId}-site:w',
            'o{orgId}-siteGeneral:r',
            'o{orgId}-siteGeneral:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
          ],
        },
        {
          key: entryStatesTableRoute,
          title: t('Entry states'),
          route: entryStatesTableRoute,
          hidden: mergedOpenpathConfig.ENTRY_STATES_MENU_ITEM_HIDDEN,
          scope: [
            'o{orgId}-site:r',
            'o{orgId}-site:w',
            'o{orgId}-entryState:r',
            'o{orgId}-entryState:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
          ],
          items: [
            {
              key: entryStatesCreateRoute,
              title: t('Create entry state'),
              route: entryStatesCreateRoute,
              scope: [
                'o{orgId}-site:r',
                'o{orgId}-site:w',
                'o{orgId}-entryState:r',
                'o{orgId}-entryState:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
              hidden: true,
            },
            {
              key: entryStatesEditRoute,
              title: t('Edit entry state'),
              route: entryStatesEditRoute,
              scope: [
                'o{orgId}-site:r',
                'o{orgId}-site:w',
                'o{orgId}-entryState:r',
                'o{orgId}-entryState:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
              hidden: true,
            },
          ],
        },
        {
          key: entrySchedulesTableRoute,
          title: t('Entry schedules'),
          route: entrySchedulesTableRoute,
          scope: [
            'o{orgId}-site:r',
            'o{orgId}-site:w',
            'o{orgId}-siteGeneral:r',
            'o{orgId}-siteGeneral:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
          ],
          items: [
            {
              key: entrySchedulesCreateRoute,
              title: t('Create schedule'),
              route: entrySchedulesCreateRoute,
              scope: [
                'o{orgId}-site:r',
                'o{orgId}-site:w',
                'o{orgId}-siteGeneral:r',
                'o{orgId}-siteGeneral:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
              hidden: true,
            },
            {
              key: entrySchedulesEditRoute,
              title: t('Edit schedule'),
              route: entrySchedulesEditRoute,
              scope: [
                'o{orgId}-site:r',
                'o{orgId}-site:w',
                'o{orgId}-siteGeneral:r',
                'o{orgId}-siteGeneral:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
              hidden: true,
            },
          ],
        },
        {
          key: lockdownTableRoute,
          title: t('Lockdown plans'),
          route: lockdownTableRoute,
          scope: [
            'o{orgId}-ldp:r',
            'o{orgId}-ldp:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
          ],
          items: [
            {
              key: lockdownCreateRoute,
              title: t('Create lockdown plan'),
              route: lockdownCreateRoute,
              scope: [
                'o{orgId}-ldp:r',
                'o{orgId}-ldp:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
              hidden: true,
            },
            {
              key: lockdownEditRoute,
              title: t('Edit lockdown plan'),
              route: lockdownEditRoute,
              scope: [
                'o{orgId}-ldp:r',
                'o{orgId}-ldp:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
              hidden: true,
            },
            {
              key: lockdownEditConfigRoute,
              title: t('Edit lockdown user config'),
              route: lockdownEditConfigRoute,
              scope: [
                'o{orgId}-ldp:r',
                'o{orgId}-ldp:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
              hidden: true,
            },
          ],
        },
      ],
    },

    {
      key: 'primary-devices',
      title: t('Devices'),
      icon: <DevicesOutlinedSvg />,
      selectedIcon: <DeviceFilledSvg />,
      scope: ['o{orgId}-hw:r', 'o{orgId}-hw:w', 'o:w', 'o:r', 's-o:r', 's-o:w'],
      items: [
        {
          key: acusTableRoute,
          title: t('ACUs'),
          route: acusTableRoute,
          scope: [
            'o{orgId}-hw:r',
            'o{orgId}-hw:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
          ],
          items: [
            {
              key: acusCreateRoute,
              title: t('Create ACU'),
              route: acusCreateRoute,
              scope: [
                'o{orgId}-hw:r',
                'o{orgId}-hw:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
              hidden: true,
            },
            {
              key: acusEditRoute,
              title: t('Edit ACU'),
              route: acusEditRoute,
              scope: [
                'o{orgId}-hw:r',
                'o{orgId}-hw:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
              hidden: true,
            },
            {
              key: acusPortsRoute,
              title: t('Edit ACU ports'),
              route: acusPortsRoute,
              scope: [
                'o{orgId}-hw:r',
                'o{orgId}-hw:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
              hidden: true,
            },
          ],
        },
        {
          key: readersTableRoute,
          title: t('Readers'),
          route: readersTableRoute,
          scope: [
            'o{orgId}-hw:r',
            'o{orgId}-hw:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
          ],
          items: [
            {
              key: readersCreateRoute,
              title: t('Create reader'),
              route: readersCreateRoute,
              scope: [
                'o{orgId}-hw:r',
                'o{orgId}-hw:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
              hidden: true,
            },
            {
              key: readersEditRoute,
              title: t('Edit reader'),
              route: readersEditRoute,
              scope: [
                'o{orgId}-hw:r',
                'o{orgId}-hw:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
              hidden: true,
            },
            {
              key: allegionReadersTableRoute,
              title: t('Third-party readers'),
              route: allegionReadersTableRoute,
              scope: [
                'o{orgId}-hw:r',
                'o{orgId}-hw:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
              hidden: true,
            },
            {
              key: thirdPartyReadersEditRoute,
              title: t('Edit third-party reader'),
              route: thirdPartyReadersEditRoute,
              scope: [
                'o{orgId}-hw:r',
                'o{orgId}-hw:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
              hidden: true,
            },
          ],
        },
        {
          key: videoReadersRoute,
          title: t('Video readers'),
          route: videoReadersRoute,
          scope: [
            'o{orgId}-hw:r',
            'o{orgId}-hw:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
          ],
        },
        {
          key: videoIntercomReadersRoute,
          title: t('Intercoms'),
          route: videoIntercomReadersRoute,
          scope: [
            'o{orgId}-hw:r',
            'o{orgId}-hw:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
          ],
        },
        {
          key: wirelessLocksTableRoute,
          title: t('Wireless locks'),
          route: wirelessLocksTableRoute,
          scope: [
            'o{orgId}-hw:r',
            'o{orgId}-hw:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
          ],
          items: [
            {
              key: wirelessLocksEditRoute,
              title: t('Edit wireless lock'),
              route: wirelessLocksEditRoute,
              scope: [
                'o{orgId}-hw:r',
                'o{orgId}-hw:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
              hidden: true,
            },
            {
              key: allegionWirelessLocksTableRoute,
              title: 'Allegion wireless locks',
              route: allegionWirelessLocksTableRoute,
              scope: [
                'o{orgId}-hw:r',
                'o{orgId}-hw:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
              hidden: true,
            },
          ],
        },
        {
          key: wirelessLockGatewaysTableRoute,
          title: t('Wireless Gateways'),
          route: wirelessLockGatewaysTableRoute,
          scope: [
            'o{orgId}-hw:r',
            'o{orgId}-hw:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
          ],
          items: [],
        },
        {
          key: deviceUpdateManagement,
          title: t('Updates'),
          route: deviceUpdateManagement,
          scope: [
            'o{orgId}-hw:r',
            'o{orgId}-hw:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
          ],
        },
      ],
    },

    {
      key: 'primary-reports',
      title: t('Reports'),
      icon: <FileOutlinedSvg />,
      selectedIcon: <FileFilledSvg />,
      scope: [
        'o{orgId}-rpt:r',
        'o{orgId}-rpt:w',
        'o{orgId}-rptGeneral:r',
        'o{orgId}-rptGeneral:w',
        'o{orgId}-musterReport:r',
        'o{orgId}-musterReport:w',
        'o{orgId}-occupancyReport:r',
        'o{orgId}-occupancyReport:w',
        'o{orgId}-entryAccessAudit:r',
        'o{orgId}-entryAccessAudit:w',
        'o{orgId}-portalAudit:r',
        'o{orgId}-portalAudit:w',
        'o{orgId}-credential:r',
        'o{orgId}-credential:w',
        'o:w',
        'o:r',
        's-o:r',
        's-o:w',
      ],
      items: [
        {
          key: 'reports-misc-group',
          type: 'group',
          title: '',
          items: [
            {
              key: alarmReportRoute,
              title: t('Alarms'),
              route: alarmReportRoute,
              scope: [
                'o{orgId}-rpt:r',
                'o{orgId}-rpt:w',
                'o{orgId}-rptAlarm:r',
                'o{orgId}-rptAlarm:w',
                'o:w',
                'o:r',
              ],
            },
            {
              key: userCredentialsManagementRoute,
              title: t('Credentials'),
              route: userCredentialsManagementRoute,
              scope: [
                'o{orgId}-rpt:r',
                'o{orgId}-rpt:w',
                'o{orgId}-credential:r',
                'o{orgId}-credential:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
            },
            {
              key: musterReportsRoute,
              title: t('Muster'),
              route: musterReportsRoute,
              scope: [
                'o{orgId}-rpt:r',
                'o{orgId}-rpt:w',
                'o{orgId}-musterReport:r',
                'o{orgId}-musterReport:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
            },
            {
              key: occupancyReportRoute,
              title: t('Occupancy'),
              route: occupancyReportRoute,
              scope: [
                'o{orgId}-rpt:r',
                'o{orgId}-rpt:w',
                'o{orgId}-occupancyReport:r',
                'o{orgId}-occupancyReport:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
            },
            {
              key: scheduledReportsRoute,
              title: t('Scheduled'),
              route: scheduledReportsRoute,
              scope: [
                'o{orgId}-rpt:r',
                'o{orgId}-rpt:w',
                'o{orgId}-rptGeneral:w',
                'o{orgId}-rptGeneral:r',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
              hidden: mergedOpenpathConfig.SCHEDULED_REPORTS_DISABLED,
              items: [
                {
                  key: scheduledReportsCreateRoute,
                  title: t('Create scheduled report'),
                  route: scheduledReportsCreateRoute,
                  scope: [
                    'o{orgId}-rpt:r',
                    'o{orgId}-rpt:w',
                    'o{orgId}-credential:r',
                    'o{orgId}-credential:w',
                    'o:w',
                    'o:r',
                    's-o:r',
                    's-o:w',
                  ],
                  hidden: true,
                },
                {
                  key: scheduledReportsEditRoute,
                  title: t('Edit scheduled report'),
                  route: scheduledReportsEditRoute,
                  scope: [
                    'o{orgId}-rpt:r',
                    'o{orgId}-rpt:w',
                    'o{orgId}-credential:r',
                    'o{orgId}-credential:w',
                    'o:w',
                    'o:r',
                    's-o:r',
                    's-o:w',
                  ],
                  hidden: true,
                },
              ],
            },
            {
              key: presenceReportRoute,
              title: t('Presence'),
              route: presenceReportRoute,
              scope: [
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
                'o{orgId}-presence:r',
                'o{orgId}-presence:w',
              ],
              hidden: mergedOpenpathConfig.PRESENCE_HIDDEN,
            },
          ],
        },

        {
          key: 'reports-activity-group',
          type: 'group',
          title: t('Activity'),
          items: [
            {
              key: activityLogsRoute,
              title: t('Logs'),
              route: activityLogsRoute,
              scope: [
                'o{orgId}-rpt:r',
                'o{orgId}-rpt:w',
                'o{orgId}-rptGeneral:r',
                'o{orgId}-rptGeneral:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
            },
            {
              key: activityInsightsRoute,
              title: t('Insights'),
              route: activityInsightsRoute,
              hidden: !mergedOpenpathConfig.SHOW_ACTIVITY_INSIGHTS_PAGE,
              scope: [
                'o{orgId}-rpt:r',
                'o{orgId}-rpt:w',
                'o{orgId}-rptGeneral:r',
                'o{orgId}-rptGeneral:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
            },
            {
              key: entryActivityRoute,
              title: t('Entry'),
              route: entryActivityRoute,
              scope: [
                'o{orgId}-rpt:r',
                'o{orgId}-rpt:w',
                'o{orgId}-rptGeneral:r',
                'o{orgId}-rptGeneral:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
            },
            {
              key: entryActivitySummaryRoute,
              title: t('Entry summary'),
              route: entryActivitySummaryRoute,
              scope: [
                'o{orgId}-rpt:r',
                'o{orgId}-rpt:w',
                'o{orgId}-rptGeneral:r',
                'o{orgId}-rptGeneral:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
            },
            {
              key: userActivityRoute,
              title: t('User'),
              route: userActivityRoute,
              scope: [
                'o{orgId}-rpt:r',
                'o{orgId}-rpt:w',
                'o{orgId}-rptGeneral:r',
                'o{orgId}-rptGeneral:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
            },
            {
              key: userActivitySummaryRoute,
              title: t('User summary'),
              route: userActivitySummaryRoute,
              scope: [
                'o{orgId}-rpt:r',
                'o{orgId}-rpt:w',
                'o{orgId}-rptGeneral:r',
                'o{orgId}-rptGeneral:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
            },
            {
              key: visualActivityReportRoute,
              title: t('Visual event'),
              route: visualActivityReportRoute,
              scope: [
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
                'o{orgId}-videoProviderPlayback:w',
              ],
              hidden: !mergedOpenpathConfig.SHOW_VISUAL_ACTIVITY_REPORT,
            },
          ],
        },

        {
          key: 'reports-audit-group',
          type: 'group',
          title: t('Audits'),
          items: [
            {
              key: entryAccessAuditRoute,
              title: t('Entry access'),
              route: entryAccessAuditRoute,
              scope: [
                'o{orgId}-rpt:r',
                'o{orgId}-rpt:w',
                'o{orgId}-entryAccessAudit:r',
                'o{orgId}-entryAccessAudit:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
            },
            {
              key: userAccessAuditRoute,
              title: t('User access'),
              route: userAccessAuditRoute,
              scope: [
                'o{orgId}-rpt:r',
                'o{orgId}-rpt:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
            },
            {
              key: portalAuditReportRoute,
              title: t('Portal'),
              route: portalAuditReportRoute,
              scope: [
                'o{orgId}-rpt:r',
                'o{orgId}-rpt:w',
                'o{orgId}-portalAudit:r',
                'o{orgId}-portalAudit:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
              hidden: mergedOpenpathConfig.API_AUDIT_REPORT_HIDDEN,
            },
          ],
        },
      ],
    },

    {
      key: 'primary-apps',
      title: t('Apps'),
      icon: <PuzzleOutlinedSvg />,
      selectedIcon: <PuzzleFilledSvg />,
      scope: [
        'o{orgId}-integrations:r',
        'o{orgId}-integrations:w',
        'o:w',
        'o:r',
        's-o:r',
        's-o:w',
      ],
      hidden:
        mergedOpenpathConfig.APP_MARKETPLACE_DISABLED ||
        (!isLicenseBased && !hasAppMarketplace),
      items: [
        {
          key: appMarketplaceRoute,
          title: t('Get apps'),
          route: appMarketplaceRoute,
          scope: [
            'o{orgId}-integrations:r',
            'o{orgId}-integrations:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
          ],
        },
        {
          key: myAppsTableRoute,
          title: t('My apps'),
          route: myAppsTableRoute,
          scope: [
            'o{orgId}-integrations:r',
            'o{orgId}-integrations:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
          ],
        },
      ],
    },

    {
      key: integrationsRoute,
      title: t('Apps'),
      route: integrationsRoute,
      icon: <PuzzleOutlinedSvg />,
      selectedIcon: <PuzzleFilledSvg />,
      hidden:
        mergedOpenpathConfig.INTEGRATIONS_MENU_ITEM_HIDDEN ||
        isLicenseBased ||
        hasAppMarketplace,
      scope: [
        'o{orgId}-integrations:r',
        'o{orgId}-integrations:w',
        'o:w',
        'o:r',
        's-o:r',
        's-o:w',
      ],
      items: [
        {
          key: integrationsIdentityProviderGsuite,
          title: t('GSuite'),
          route: integrationsIdentityProviderGsuite,
          scope: [
            'o{orgId}-integrations:r',
            'o{orgId}-integrations:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
          ],
          hidden: true,
        },
        {
          key: integrationsIdentityProviderAzure,
          title: t('Azure'),
          route: integrationsIdentityProviderAzure,
          scope: [
            'o{orgId}-integrations:r',
            'o{orgId}-integrations:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
          ],
          hidden: true,
        },
        {
          key: integrationsIdentityProviderOkta,
          title: t('Okta'),
          route: integrationsIdentityProviderOkta,
          scope: [
            'o{orgId}-integrations:r',
            'o{orgId}-integrations:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
          ],
          hidden: true,
        },
        {
          key: integrationsIdentityProviderOnelogin,
          title: t('Onelogin'),
          route: integrationsIdentityProviderOnelogin,
          scope: [
            'o{orgId}-integrations:r',
            'o{orgId}-integrations:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
          ],
          hidden: true,
        },
      ],
    },

    {
      key: 'primary-config',
      title: t('Config'),
      icon: <SettingOutlinedSvg />,
      selectedIcon: <SettingFilledSvg />,
      scope: [
        'o{orgId}-configurations:r',
        'o{orgId}-configurations:w',
        'o{orgId}-alarmConfigurations:r',
        'o{orgId}-alarmConfigurations:w',
        'o{orgId}-outboundWebhooks:r',
        'o{orgId}-outboundWebhooks:w',
        'o{orgId}-alertSettings:r',
        'o{orgId}-alertSettings:w',
        'o{orgId}-mobileAppSettings:r',
        'o{orgId}-mobileAppSettings:w',
        'o{orgId}-badgePrinting:r',
        'o{orgId}-badgePrinting:w',
        'o:w',
        'o:r',
        's-o:r',
        's-o:w',
      ],
      items: [
        {
          key: alarmConfigurationsRoute,
          title: t('Alarms'),
          route: alarmConfigurationsRoute,
          scope: [
            'o{orgId}-configurations:r',
            'o{orgId}-configurations:w',
            'o{orgId}-alarmConfigurations:r',
            'o{orgId}-alarmConfigurations:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
          ],
        },
        {
          key: subscriptionsTableRoute,
          title: t('Rules'),
          route: subscriptionsTableRoute,
          scope: [
            'o{orgId}-configurations:r',
            'o{orgId}-configurations:w',
            'o{orgId}-outboundWebhooks:r',
            'o{orgId}-outboundWebhooks:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
          ],
          items: [
            {
              key: subscriptionsCreateRoute,
              title: t('Create rule'),
              route: subscriptionsCreateRoute,
              scope: [
                'o{orgId}-configurations:r',
                'o{orgId}-configurations:w',
                'o{orgId}-outboundWebhooks:r',
                'o{orgId}-outboundWebhooks:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
              hidden: true,
            },
            {
              key: subscriptionsEditRoute,
              title: t('Edit rule'),
              route: subscriptionsEditRoute,
              scope: [
                'o{orgId}-configurations:r',
                'o{orgId}-configurations:w',
                'o{orgId}-outboundWebhooks:r',
                'o{orgId}-outboundWebhooks:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
              hidden: true,
            },
          ],
        },
        {
          key: alertSettingsRoute,
          title: t('Alerts'),
          route: alertSettingsRoute,
          hidden: mergedOpenpathConfig.ALERTS_SETTINGS_MENU_ITEM_HIDDEN,
          scope: [
            'o{orgId}-configurations:r',
            'o{orgId}-configurations:w',
            'o{orgId}-alertSettings:r',
            'o{orgId}-alertSettings:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
          ],
        },
        {
          key: mobileAppSettingsRoute,
          title: t('Mobile app'),
          route: mobileAppSettingsRoute,
          hidden: mergedOpenpathConfig.ADMINISTRATION_MENU_ITEM_HIDDEN,
          scope: [
            'o{orgId}-configurations:r',
            'o{orgId}-configurations:w',
            'o{orgId}-mobileAppSettings:r',
            'o{orgId}-mobileAppSettings:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
          ],
          items: [
            {
              key: mobileAppBadgeUiRoute,
              title: t('Badge UI'),
              route: mobileAppBadgeUiRoute,
              scope: [
                'o{orgId}-configurations:r',
                'o{orgId}-configurations:w',
                'o{orgId}-mobileAppSettings:r',
                'o{orgId}-mobileAppSettings:w',
                'o:w',
                'o:r',
                's-o:r',
                's-o:w',
              ],
              hidden: true,
            },
          ],
        },
        {
          key: badgeTemplatesTablePageRoute,
          title: t('Badge templates'),
          route: badgeTemplatesTablePageRoute,
          requiredFeatureCode: 'badgePrinting',
          scope: [
            'o{orgId}-configurations:r',
            'o{orgId}-configurations:w',
            'o{orgId}-badgePrinting:r',
            'o{orgId}-badgePrinting:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
          ],
        },
        {
          key: intercomUserDirectoryRoute,
          title: t('Intercom users'),
          route: intercomUserDirectoryRoute,
          scope: [
            'o{orgId}-configurations:r',
            'o{orgId}-configurations:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
          ],
        },
      ],
    },

    {
      key: 'primary-admin',
      title: t('Admin'),
      icon: <AdminOutlinedSvg />,
      selectedIcon: <AdminFilledSvg />,
      scope: [
        'o{orgId}-admin:r',
        'o{orgId}-admin:w',
        'o{orgId}-account:r',
        'o{orgId}-account:w',
        'o{orgId}-quickStart:r',
        'o{orgId}-quickStart:w',
        'o:w',
        'o:r',
        's-o:r',
        's-o:w',
      ],
      items: [
        {
          key: accountPageRoute,
          title: t('Account'),
          route: accountPageRoute,
          scope: [
            'o{orgId}-admin:r',
            'o{orgId}-admin:w',
            'o{orgId}-account:r',
            'o{orgId}-account:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
          ],
        },
        {
          key: licensesRoute,
          title: t('Requests'),
          route: licensesRoute,
          scope: [
            'o{orgId}-integrations:r',
            'o{orgId}-integrations:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
          ],
          hidden:
            mergedOpenpathConfig.APP_MARKETPLACE_DISABLED ||
            isLicenseBased ||
            !hasAppMarketplace ||
            (hasAppMarketplace && billingMode !== 'channel'),
        },
        {
          key: licensesRoute,
          title: t('Licenses'),
          route: licensesRoute,
          scope: [
            'o{orgId}-integrations:r',
            'o{orgId}-integrations:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
          ],
          hidden:
            mergedOpenpathConfig.APP_MARKETPLACE_DISABLED || !isLicenseBased,
        },
        {
          key: quickStartRoute,
          title: t('Quick start'),
          route: quickStartRoute,
          scope: [
            'o{orgId}-admin:r',
            'o{orgId}-admin:w',
            'o{orgId}-quickStart:r',
            'o{orgId}-quickStart:w',
            'o:w',
            'o:r',
            's-o:r',
            's-o:w',
          ],
        },
      ],
    },

    {
      key: releaseNotesRoute,
      title: t('Releases'),
      route: releaseNotesRoute,
      icon:
        newReleaseNotesCount > 0 ? (
          <Badge dot>
            <DocumentOutlinedSvg />
          </Badge>
        ) : (
          <DocumentOutlinedSvg />
        ),
      selectedIcon:
        newReleaseNotesCount > 0 ? (
          <Badge dot>
            <DocumentFilledSvg />
          </Badge>
        ) : (
          <DocumentFilledSvg />
        ),
      scope: [
        'o{orgId}-admin:r',
        'o{orgId}-admin:w',
        'o:w',
        'o:r',
        's-o:r',
        's-o:w',
      ],
      hidden: true,
    },

    /**
     * Master mode top level menu items
     */
    {
      admin: true,
      key: masterDashboardRoute,
      title: t('Home'),
      route: masterDashboardRoute,
      icon: <HomeOutlinedSvg />,
      selectedIcon: <HomeFilledSvg />,
      scope: [
        'o:r',
        'o:w',
        'o-basic:r',
        'o-basic:w',
        'i:r',
        'i:w',
        's-o:r',
        's-o:w',
      ],
    },

    {
      admin: true,
      key: 'master-primary-manage',
      title: t('Manage'),
      icon: <AdminOutlinedSvg />,
      selectedIcon: <AdminFilledSvg />,
      scope: [
        'o:r',
        'o:w',
        'o-basic:r',
        'o-basic:w',
        's-o:r',
        's-o:w',
        'o-support:r',
        'o-support:w',
        'i:r',
        'i:w',
      ],
      items: [
        {
          admin: true,
          key: masterOrgTableRoute,
          title: t('Orgs'),
          route: masterOrgTableRoute,
          scope: [
            'o:r',
            'o:w',
            'o-basic:r',
            'o-basic:w',
            's-o:r',
            's-o:w',
            'o-support:r',
            'o-support:w',
          ],
          items: [
            {
              admin: true,
              key: masterOrgCreateRoute,
              title: t('Create organization'),
              route: masterOrgCreateRoute,
              scope: ['o:w', 'o-basic:w', 's-o:w'],
              hidden: true,
            },
            {
              admin: true,
              key: masterOrgEditRoute,
              title: t('Edit organization'),
              route: masterOrgEditRoute,
              scope: ['o:r', 'o:w', 'o-basic:r', 'o-basic:w', 's-o:r', 's-o:w'],
              hidden: true,
            },
            {
              admin: true,
              key: masterOrgEditPackageRoute,
              title: t('Edit organization package'),
              route: masterOrgEditPackageRoute,
              scope: ['o:r', 'o:w', 'o-basic:r', 'o-basic:w', 's-o:r', 's-o:w'],
              hidden: true,
            },
            {
              admin: true,
              key: masterOrgEditFeatureFlagRoute,
              title: t('Edit organization feature flag'),
              route: masterOrgEditFeatureFlagRoute,
              scope: [
                'o:r',
                'o:w',
                's-o:r',
                's-o:w', // Writes will fail for s-o but they can still view them here...
                'o-support:r',
                'o-support:w',
              ],
              hidden: true,
            },
          ],
        },

        {
          admin: true,
          key: masterIdentitiesTableRoute,
          title: t('Identities'),
          route: masterIdentitiesTableRoute,
          scope: ['i:r', 'i:w'],
          items: [
            {
              admin: true,
              key: masterIdentitiesEditRoute,
              title: t('Edit Identity'),
              route: masterIdentitiesEditRoute,
              scope: ['i:r', 'i:w'],
              hidden: true,
            },
            {
              admin: true,
              key: masterIdentitiesEditSecurityRoute,
              title: t('Edit identity security'),
              route: masterIdentitiesEditSecurityRoute,
              scope: ['i:r', 'i:w'],
              hidden: true,
            },
            {
              admin: true,
              key: masterIdentitiesCreateRoute,
              title: t('Create identity'),
              route: masterIdentitiesCreateRoute,
              scope: ['i:r', 'i:w'],
              hidden: true,
            },
          ],
        },

        {
          admin: true,
          key: masterRolesTableRoute,
          title: t('Identity roles'),
          route: masterRolesTableRoute,
          scope: ['i:r', 'i:w'],
          items: [
            {
              admin: true,
              key: masterRolesEditRoute,
              title: t('Edit role'),
              route: masterRolesEditRoute,
              scope: ['i:r', 'i:w'],
              hidden: true,
            },
            {
              admin: true,
              key: masterRolesCreateRoute,
              title: t('Create role'),
              route: masterRolesCreateRoute,
              scope: ['i:r', 'i:w'],
              hidden: true,
            },
          ],
        },

        {
          admin: true,
          key: masterAcusTableRoute,
          title: t('ACUs'),
          route: masterAcusTableRoute,
          scope: ['o:r', 'o:w', 's-o:r', 's-o:w'],
          items: [
            {
              admin: true,
              key: masterAcusEditRoute,
              title: t('View ACU'),
              route: masterAcusEditRoute,
              scope: ['o:r', 'o:w', 's-o:r', 's-o:w'],
              hidden: true,
            },
          ],
        },

        {
          admin: true,
          key: masterEntriesTableRoute,
          title: t('Entries'),
          route: masterEntriesTableRoute,
          scope: ['o:r', 'o:w'], // no s-o here, since this page is new and we are migrating partners into Partner Center
        },

        {
          admin: true,
          key: masterThirdPartyDevicesRoute,
          title: t('3rd-party devices'),
          route: masterThirdPartyDevicesRoute,
          scope: ['o:r', 'o:w', 's-o:r', 's-o:w'],
        },

        {
          admin: true,
          key: masterAllegionFobsRoute,
          title: t('Allegion fobs'),
          route: masterAllegionFobsRoute,
          scope: ['o:r', 'o:w'],
        },

        {
          admin: true,
          key: masterFeatureFlagsTableRoute,
          title: t('Feature flags'),
          route: masterFeatureFlagsTableRoute,
          scope: ['o:r', 'o:w', 'o-support:r', 'o-support:w'],
          items: [
            {
              admin: true,
              key: masterFeatureFlagsCreateRoute,
              title: t('Create feature flag'),
              route: masterFeatureFlagsCreateRoute,
              scope: ['o:w', 'o-support:w'],
              hidden: true,
            },
            {
              admin: true,
              key: masterFeatureFlagsEditRoute,
              title: t('Edit feature flag'),
              route: masterFeatureFlagsEditRoute,
              scope: ['o:r', 'o:w', 'o-support:r', 'o-support:w'],
              hidden: true,
            },
          ],
        },
      ],
    },

    {
      admin: true,
      key: 'master-primary-reports',
      title: t('Reports'),
      icon: <FileOutlinedSvg />,
      selectedIcon: <FileFilledSvg />,
      scope: ['o:r', 'o:w', 'o-support:r', 'o-support:w', 'o-acu:r', 'o-acu:w'],
      items: [
        {
          admin: true,
          key: masterPortalAuditReportRoute,
          title: t('System audit'),
          route: masterPortalAuditReportRoute,
          scope: ['o:r', 'o:w', 'o-support:r', 'o-support:w'],
        },

        {
          admin: true,
          key: masterAcuSoftwareReportRoute,
          title: t('ACU software'),
          route: masterAcuSoftwareReportRoute,
          scope: [
            'o:r',
            'o:w',
            'o-acu:r',
            'o-acu:w',
            'o-support:r',
            'o-support:w',
          ],
        },
      ],
    },

    {
      admin: true,
      key: masterReleaseNotesRoute,
      title: t('Releases'),
      route: masterReleaseNotesRoute,
      icon:
        newMasterReleaseNotesCount > 0 ? (
          <Badge dot>
            <DocumentOutlinedSvg />
          </Badge>
        ) : (
          <DocumentOutlinedSvg />
        ),
      selectedIcon:
        newMasterReleaseNotesCount > 0 ? (
          <Badge dot>
            <DocumentFilledSvg />
          </Badge>
        ) : (
          <DocumentFilledSvg />
        ),
      scope: ['o:r', 'o:w', 's-o:r', 's-o:w'],
    },

    {
      admin: true,
      key: chatRoute,
      title: t('Insights'),
      route: chatRoute,
      icon: <SparkIcon />,
      selectedIcon: <SparkIcon />,
      hidden: !mergedOpenpathConfig.SHOW_ALTA_SUPPORT_INTELLIGENCE_CHAT,
      scope: ['o:r', 'o:w', 's-o:r', 's-o:w'],
    },

    {
      admin: true,
      key: 'master-primary-resources',
      title: t('Resources'),
      icon: <QuestionMarkOutlinedSvg />,
      selectedIcon: <QuestionMarkFilledSvg />,
      scope: ['s-o:r', 's-o:w'],
      items: [
        {
          admin: true,
          key: 'master-secondary-marketing',
          title: t('Marketing'),
          route: 'https://loginvsa.motorolasolutions.com/',
          scope: ['s-o:r', 's-o:w'],
          external: true,
        },

        {
          admin: true,
          key: 'https://www.openpath.com/openpath-university',
          title: t('Training'),
          route: 'https://www.openpath.com/openpath-university',
          scope: ['s-o:r', 's-o:w'],
          external: true,
        },
      ],
    },

    /**
     * Hidden top level menu items
     */
    {
      key: requiredTermsRoute,
      title: t('Required terms'),
      route: requiredTermsRoute,
      hidden: true,
    },

    {
      key: errorMessageRoute,
      title: t('No permission'),
      route: errorMessageRoute,
      hidden: true,
    },

    // Can open from anywhere, not a real page, just a slideout!
    {
      key: alarmEditRoute,
      title: t('Alarm edit'),
      route: alarmEditRoute,
      scope: [
        'o{orgId}-dash:r',
        'o{orgId}-dash:w',
        'o{orgId}-dashAlarm:r',
        'o{orgId}-dashAlarm:w',
        'o{orgId}-rpt:r',
        'o{orgId}-rpt:w',
        'o{orgId}-rptAlarm:r',
        'o{orgId}-rptAlarm:w',
        'o{orgId}-configurations:r',
        'o{orgId}-configurations:w',
        'o{orgId}-alarmActions:r',
        'o{orgId}-alarmActions:w',
        'o:r',
        'o:w',
      ],
      hidden: true,
    },

    {
      key: 'developer-menu',
      hidden: true,
      // hidden: !mergedOpenpathConfig.SHOW_DEVELOPER_MENU,
      title: t('Developer menu'),
      scope: [],
      items: [
        {
          key: 'https://react.semantic-ui.com/',
          title: t('Semantic UI react (docs)'),
          external: true,
          route: 'https://react.semantic-ui.com/',
          scope: [],
        },
      ],
    },
  ] satisfies OpRawMenuItemType[];
};
