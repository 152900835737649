import React from 'react';
import {
  SvgWrapper,
  SvgWrapperProps,
} from 'components/svgs/SvgWrapper/SvgWrapper';

interface UserFilledSvgProps extends Omit<SvgWrapperProps, 'children'> {}

export const UserFilledSvg = ({ ...svgProps }: UserFilledSvgProps) => (
  <SvgWrapper viewBox="0 0 16 16" {...svgProps}>
    <path d="M13.3823 10.087C15.4443 10.498 17.0043 12.319 17.0043 14.5V16.5C17.0043 16.777 16.7793 17.002 16.5023 17.002H1.50231C1.22431 17.002 1.00031 16.777 1.00031 16.5V14.5C1.00031 12.319 2.55931 10.498 4.62131 10.087C5.71731 11.261 7.27331 12 9.00231 12C10.7303 12 12.2863 11.261 13.3823 10.087ZM9.002 1C11.763 1 14.002 3.239 14.002 6C14.002 8.761 11.763 11 9.002 11C6.241 11 4.00201 8.761 4.00201 6C4.00201 3.239 6.241 1 9.002 1Z" />
  </SvgWrapper>
);
