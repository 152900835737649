import * as Sentry from '@sentry/react';
import {
  take,
  takeEvery,
  all,
  call,
  put,
  select,
  fork,
  spawn,
  delay,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { getItemConfig, getRawMenuItems } from 'config/utils/index';
import {
  meetsMinimumVersion,
  requestAndSet,
  sendToHelium,
} from 'utils/helpers';
import {
  activityDashboardRoute,
  requiredTermsRoute,
  loginRoute,
  errorMessageRoute,
  accountPageRoute,
  masterDashboardRoute,
  deviceUpdateManagement,
} from 'routes/constants';
import {
  FIRE_SCOPE_CHECK,
  FIRE_AUTH_REDIRECT_CHECK,
  CLEAR_AUTHENTICATED_CONTAINER_STORE_DATA,
  CHANGE_ROUTE,
  ROOT_CONTAINER_MOUNTED,
  REQUEST_AND_SET_IDENTITY,
  REQUEST_PACKAGE_UPGRADE,
} from 'routes/AuthenticatedContainer/constants';
import { LOGIN_SUCCESS } from 'routes/AppContainer/constants';
import {
  rootContainerMounted,
  clearOrgSpecificStoreData,
  changeRoute as changeRouteAction,
} from 'routes/AuthenticatedContainer/actions';
import {
  clearAlert,
  setCurrentOrg,
  loginSuccess,
  setAlert,
  initStoreFromCookie,
  setIdentityInfo,
} from 'routes/AppContainer/actions';
import {
  selectIsLoginComplete,
  selectCurrentIdentityId,
  selectRefreshRequired,
  selectCurrentOrgParentId,
  selectCurrentIdentityInfo,
} from 'routes/AppContainer/selectors';
import {
  selectCurrentOrgId,
  selectCurrentUserId,
  selectProcessedScopes,
  selectAccessTokenScopes,
  selectActiveScopes,
  selectUserIdForOrg,
} from 'global/accessToken/selectors';
import { selectOrg } from 'global/billing/selectors';
import { addBannerData, removeAllBanners } from 'global/banner/actions';
import {
  loadNavigation,
  activateNavMenuItemByPath,
} from 'containers/NavMenu/actions';
import { checkScope, getActiveScopes, verifyApiScope } from 'utils/redirects';
import {
  selectFeatureFlag,
  selectMergedConfig,
} from 'global/openpathconfig/selectors';
import { setRemoteFeatureFlags } from 'global/accessToken/actions';
import { requestOrgFeatures } from 'global/orgFeatures/sagas';
import { requestAllFeatures } from 'global/features/sagas';
import { deleteSlideOut } from 'global/slideOuts/actions';
import { t } from 'i18next';
import { selectSlideOuts } from 'global/slideOuts/selectors';
import i18n from 'i18n/i18n';
import { Trans } from 'react-i18next';
import { OpTypography } from 'new-components/DLS/OpTypography/OpTypography';
import { OpExternalLink } from 'new-components/DLS/OpExternalLink/OpExternalLink';
import { OpLink } from 'new-components/DLS/OpLink/OpLink';
import { getAccessToken } from 'utils/accessToken';
import { getWindowLocation } from 'utils/window';

const { Link } = OpTypography;

function setPageTitle(title, orgName) {
  if (!title && !orgName) {
    window.document.title = t(`Access`);
  } else if (!orgName) {
    window.document.title = t(`Avigilon Alta - {{title}} - {{orgName}}`, {
      title,
      orgName,
    });
  } else {
    window.document.title = t(`Avigilon Alta - {{title}}`, {
      title,
    });
  }
}

export function* requestAndSetIdentity() {
  const org = yield select(selectOrg());
  const currentScopes = yield select(selectActiveScopes());
  const identityId = yield select(selectCurrentIdentityId());
  const { data: identity, errorMessage } = yield call(
    requestAndSet,
    'describeIdentity',
    [identityId],
    {
      createSetterAction: ({ data }) => setIdentityInfo(data),
    },
  );

  if (errorMessage) {
    return;
  }

  const { firstName, lastName, email, users, namespace, language } = identity;

  const userIds = users.map((u) => u.id);

  i18n.changeLanguage(language);

  const dontForceTerms = yield select(
    selectFeatureFlag('DONT_FORCE_TERMS_SIGNATURE'),
  );
  const canSignTerms =
    checkScope(org.get('id'), currentScopes, ['o{orgId}-admin:w']) &&
    namespace?.org?.id === org.get('id');

  if (canSignTerms && !dontForceTerms && org.get('termsStatus') !== 'A') {
    yield put(changeRouteAction(requiredTermsRoute));
  }

  Sentry.setUser({
    id: identityId,
    identityId,
    firstName,
    lastName,
    email,
    userIds,
  });

  return identity;
}

// mainly used to convert "1" or "true" to true from the API
const convertFeatureFlagTypeValue = (data) => {
  switch (data.featureFlag.dataType) {
    case 'boolean':
      // Helium now casts strings (e.g '1', 'true') to booleans, so handle that case
      if (typeof data.value === 'boolean') {
        return data.value;
      }
      return data.value === '1' || data.value === 'true';
    default:
      return data.value;
  }
};

export function* refreshLoginOrChangeOrg(action) {
  // if we cached an alert-on-refresh, this is where we use it
  const message = window.sessionStorage.getItem('op-alert');
  if (message) {
    yield put(setAlert('info', message));
    window.sessionStorage.removeItem('op-alert');
  }

  let isLoginComplete = yield select(selectIsLoginComplete());
  while (!isLoginComplete) {
    yield take(LOGIN_SUCCESS);
    isLoginComplete = yield select(selectIsLoginComplete());
  }
  const currentOrgId = yield select(selectCurrentOrgId());
  // we need to make sure setRemoteFeatureFlags and requestOrgFeatures
  // are done before we call loadNavigation, because the results of
  // both of those can influence the choice of which menu items are
  // visible
  if (currentOrgId) {
    yield call(requestAndSet, 'listOrgFeatureFlags', [currentOrgId], {
      createSetterAction: ({ data }) =>
        setRemoteFeatureFlags(
          currentOrgId,
          data.reduce((acc, curr) => {
            // eslint-disable-next-line no-param-reassign
            acc[curr.featureFlag.name] = convertFeatureFlagTypeValue(curr);
            return acc;
          }, {}),
        ),
    });
  }

  yield call(requestOrgFeatures);
  yield call(requestAllFeatures);
  yield put(loadNavigation());
  if (action.route) {
    yield put(activateNavMenuItemByPath(action.route));
  }

  const identityInfo = yield call(requestAndSetIdentity);
  if (!identityInfo) {
    return;
  }

  // A good place to add some temporary banners!
  if (currentOrgId) {
    // ADMIN SUPPORT CONTACT BANNER
    // Determine if the user has a super admin role with write access
    let hasSuperAdminRole = true; // If no userId, assume super admin as MM user
    const currentUserId = yield select(selectCurrentUserId());
    if (currentUserId) {
      const { data: listUserRolesData } = yield call(
        requestAndSet,
        'listUserRoles',
        [currentOrgId, currentUserId],
      );
      hasSuperAdminRole =
        listUserRolesData.map(({ id }) => id).includes(5) || // Super admin
        listUserRolesData.map(({ id }) => id).includes(19); // MFA enforced super admin
    }

    // Determine if a user in the org has a mobile credential
    const { data: listMobileCredentialsData } = yield call(
      requestAndSet,
      'listOrgCredentials',
      [currentOrgId],
      {
        queryStringParams: {
          filter: 'credentialType.modelName:=mobile',
        },
      },
    );
    const orgUserHasMobileCredential = listMobileCredentialsData?.length > 0;

    // Determine if org already has an admin support contact
    const { data: orgData } = yield call(requestAndSet, 'describeOrg', [
      currentOrgId,
    ]);

    const adminSupportContactEmail = orgData?.adminSupportContactEmails ?? '';
    const hasAdminSupportContactEmail =
      orgData?.adminSupportContactEmails?.length > 0;

    const opconfig = yield select(selectMergedConfig());
    const orgSupportEmails = adminSupportContactEmail.split(', ');
    const orgHasForbiddenSupportEmail = orgSupportEmails.some((email) =>
      opconfig.FORBIDDEN_SUPPORT_EMAILS.includes(email),
    );

    // Need to be super admin and org needs to have at least one user with a mobile credential and no existing admin support contact
    if (
      hasSuperAdminRole &&
      orgUserHasMobileCredential &&
      (!hasAdminSupportContactEmail || orgHasForbiddenSupportEmail)
    ) {
      yield put(
        addBannerData({
          key: 'admin-support-contact',
          type: 'error',
          text: {
            status: (
              <span>
                {t(
                  'Enter an email or phone number for users to contact with access-related support requests.',
                )}{' '}
                <OpLink key="add-admin-support" route={accountPageRoute}>
                  {t('Add user help contact')}
                </OpLink>
              </span>
            ),
          },
          clearable: true,
        }),
      );
    }

    // APP SWITCHER BANNER
    const orgIdsThatSeeAppSwitcherBanner = yield select(
      selectFeatureFlag('ORG_IDS_THAT_SEE_APP_SWITCHER_BANNER'),
    );

    if ((orgIdsThatSeeAppSwitcherBanner || []).includes(currentOrgId)) {
      yield put(
        addBannerData({
          key: 'app-switcher-promo',
          type: 'info',
          text: {
            status: (
              <span>
                {t(
                  'Your Access and Aware services are linking soon! Learn about the upcoming app switcher, changes to Aware login, and more.',
                )}{' '}
                <OpExternalLink url="https://help.openpath.com/space/EHC/1979449346/Unification+of+Avigilon+Alta+services+video+(Aware)+and+access+control+(Control+Center)">
                  {t('See details')}
                </OpExternalLink>
              </span>
            ),
          },
          clearable: true,
        }),
      );
    }

    const activeScopes = yield select(selectActiveScopes());
    const canUpdateAcuSoftware = verifyApiScope(
      activeScopes,
      'triggerAcuSoftwareUpdate',
      currentOrgId,
    );

    if (!canUpdateAcuSoftware) {
      return;
    }

    // CAMERA FIRMWARE UPDATE BANNER
    const { data: acuShadowStates } = yield call(
      requestAndSet,
      'listOrgAcuSyncedShadowStates',
      [currentOrgId],
      {
        queryStringParams: {
          filter: 'videoFirmwareVersion:(!=null)',
        },
        loopToGetAll: true,
      },
    );

    // Avigilon Firmware Upgrade Banner
    const needsVideoFirmwareVersionUpdate = !(acuShadowStates || []).every(
      ({ videoFirmwareVersion }) =>
        meetsMinimumVersion({
          version: videoFirmwareVersion,
          minimumVersion: '4.71',
          // Gross, I know, but hacking for ISC demo. Will remove after
          skipVersionCheck: opconfig.ENV === 'dev' && currentOrgId === 338,
        }),
    );

    if (needsVideoFirmwareVersionUpdate) {
      yield put(
        addBannerData({
          key: 'vrp-virp-update',
          type: 'error',
          ctaRoute: deviceUpdateManagement,
          text: {
            status: (
              <Trans>
                An important firmware update for the Avigilon Video Reader Pro
                and Video Intercom Reader Pro is now available. You can navigate
                to Devices {'>'} Device Updates to install the update. More
                information and learn how to install{' '}
                <Link
                  href="https://help.openpath.com/space/EHC/blog/2067595265/Device+Updates"
                  target="_blank"
                >
                  here
                </Link>
                .
              </Trans>
            ),
            cta: t('Update now'),
          },
          clearable: true,
        }),
      );
    }
  }
}

// @TODO - I think we can factor this out now
function* clearStoreData(action) {
  yield put(clearAlert(null, 'error'));
  yield put(clearAlert(null, 'warning'));
  yield call(action.callback);
}

function* fireAuthRedirect({ redirectUrl }) {
  const accessToken = getAccessToken();

  if (!accessToken) {
    let finalLoginRoute = loginRoute;

    // If the attempted route is anything but root, login, or signin append the redirectUrl url param
    if (!['/', loginRoute, '/login'].includes(redirectUrl)) {
      finalLoginRoute += `?redirectUrl=${encodeURIComponent(redirectUrl)}`;
    }

    getWindowLocation().href = finalLoginRoute;
  } else {
    yield put(initStoreFromCookie(() => null));
  }
}

function* watchClearStoreData() {
  while (true) {
    const action = yield take(CLEAR_AUTHENTICATED_CONTAINER_STORE_DATA);
    yield spawn(clearStoreData, action);
  }
}

function* watchRootContainerMounted() {
  while (true) {
    const action = yield take(ROOT_CONTAINER_MOUNTED);
    yield call(refreshLoginOrChangeOrg, action);
  }
}

function* changeRoute({ route, options: paramOptions }) {
  const currentOrgId = yield select(selectCurrentOrgId());
  const identityId = yield select(selectCurrentIdentityId());
  const currentIdentity = yield select(selectCurrentIdentityInfo());
  const slideOuts = yield select(selectSlideOuts());

  if ((slideOuts?.size || 0) > 0) {
    yield put(deleteSlideOut());
    yield delay(200);
  }

  const defaultOptions = {
    master: false,
    root: Boolean(!currentOrgId && !paramOptions?.orgId),
    orgId: currentOrgId,
    state: null,
  };
  const options = {
    ...defaultOptions,
    ...paramOptions,
  };

  const isChangingOrg = options.orgId !== currentOrgId;
  if (isChangingOrg) {
    // we're switching orgs!!
    yield put(clearOrgSpecificStoreData());
    yield put(removeAllBanners());
    let parentId = null;
    let orgName = '';
    let isLicenseBased = false;
    let currentUserPreferences = null;
    if (options.orgId) {
      const { data: orgInfo } = yield call(requestAndSet, 'describeOrg', [
        options.orgId,
      ]);

      if (orgInfo) {
        parentId = orgInfo.parentOrg ? orgInfo.parentOrg.id : null;
        orgName = orgInfo.name;
        isLicenseBased = orgInfo.isLicenseBased;
      }

      const userIdForOrg = yield select(selectUserIdForOrg(options.orgId));
      if (userIdForOrg) {
        // Get the user's preferences
        ({ data: currentUserPreferences } = yield call(
          requestAndSet,
          'describeUserPreferenceSet',
          [options.orgId, userIdForOrg],
        ));
      }
    }

    yield put(
      loginSuccess({
        identityId,
        orgId: options.orgId,
        orgName,
        parentOrgId: parentId,
        isLicenseBased,
        currentUserPreferences,
      }),
    );
    yield put(setCurrentOrg(options.orgId));
    // we "mount" the root container again since the org changed, this will re-process
    // scopes and nav items and stuff.
    yield put(rootContainerMounted(route));
    if (!options.state) {
      options.state = { changingOrg: true };
    } else {
      options.state.changingOrg = true;
    }
  }

  const path = options.root
    ? options.master
      ? `/master/${route}`
      : `/${route}`
    : `/o/${options.orgId}/${route}`;
  // activate the menu option
  yield put(activateNavMenuItemByPath(route));

  // if we've got a newer version of platinum released, do a hard refresh
  const requireForceRefresh = yield select(selectRefreshRequired());
  if (requireForceRefresh) {
    window.sessionStorage.setItem(
      'op-alert',
      t(`You have been updated to the latest version of Access.`),
    );
    getWindowLocation().href = path;
  }

  // NOTE: previously we had an override here to force nextPath to '/'
  // when (isChangingOrg && !options.master) - see
  // 1a58e80beaf76bef3eac20357282c3e94a138ef6,
  // ba44f8dacaafef5a5afa4f5ba8ed9c2e5581f2df,
  // f48221e43e4c6098e39dacbc8c8b6a66a8c0ec84 - seems to have been
  // originally (as of ~Sep 2019) needed in order to force some global
  // sagas to remount properly following an org change, by forcing
  // LoginPage to temporarily mount and then redirect to the org's
  // default initial route, but now (May 2021) an org-switch works
  // without needing to force through LoginPage, and that allows us to
  // do a changeRoute with an org-switch that goes directly to a
  // specific route for that org, without being forced to always land
  // on that org's default initial route

  let nextPath = path;

  // if they haven't signed T&C they have to...
  const org = yield select(selectOrg());
  const dontForceTerms = yield select(
    selectFeatureFlag('DONT_FORCE_TERMS_SIGNATURE'),
  );
  const currentScopes = yield select(selectActiveScopes());
  const canSignTerms =
    checkScope(currentOrgId, currentScopes, ['o{orgId}-admin:w']) &&
    currentIdentity.getIn(['namespace', 'org', 'id']) === org.get('id');

  // wow this if check sucks. Loading in on profile and going to an org (root to non-root) has undefined org/billing info
  // We might be able to improve this after the intro-sagas are cleaned up to be blocking
  if (
    canSignTerms &&
    !dontForceTerms &&
    org.get('termsStatus') &&
    org.get('termsStatus') !== 'A' &&
    !isChangingOrg &&
    !options.master &&
    !options.root
  ) {
    nextPath = `/o/${options.orgId}/requiredTerms`;
  }

  yield put(push(nextPath, options.state)); // As of react router 4, we can pass state as the second argument

  // set title for history
  const routeInfo = getItemConfig(getRawMenuItems(), route.split('?')[0]);
  // we found a valid route, let's set a dynamic title
  if (routeInfo && routeInfo.title) {
    setPageTitle(routeInfo.title, org.get('name'));
  } else {
    // something went wrong, so we'll just sit it to the default
    setPageTitle();
  }
}

function* scopeCheckHelper(allowedScopes) {
  const currentOrg = yield select(selectCurrentOrgId());
  if (!allowedScopes.length) return;
  const processedScopes = yield select(selectProcessedScopes());
  const scopes = yield select(selectAccessTokenScopes());
  const parentOrgId = yield select(selectCurrentOrgParentId());

  const activeScopes = getActiveScopes(
    currentOrg,
    scopes,
    processedScopes.get(String(parentOrgId)),
  );

  let valid = false;
  // console.debug(`[currentOrg] - ${JSON.stringify(currentOrg, null, 2)}`)
  // console.debug(`[activeScopes] - ${JSON.stringify(activeScopes, null, 2)}`)
  // console.debug(`[allowedScopes] - ${JSON.stringify(allowedScopes, null, 2)}`)
  valid = checkScope(currentOrg, activeScopes, allowedScopes);

  if (!valid) {
    // yield put(push(loginRoute))
    if (
      currentOrg &&
      checkScope(
        currentOrg,
        activeScopes,
        getItemConfig(getRawMenuItems(), activityDashboardRoute).scope,
      )
    ) {
      yield put(push(`/o/${currentOrg}/${activityDashboardRoute}`));
    } else {
      if (!currentOrg) {
        // In MM, go to Master Dash
        yield put(setAlert('error', t('Insufficient scope')));
        yield put(push(`/${masterDashboardRoute}`));
      } else {
        yield put(push(`/${errorMessageRoute}`));
      }
      yield put(push(`/${errorMessageRoute}`));
    }
  }
}

function* fireScopeCheck(action) {
  let isLoginComplete = yield select(selectIsLoginComplete());
  while (!isLoginComplete) {
    yield take(LOGIN_SUCCESS);
    isLoginComplete = yield select(selectIsLoginComplete());
  }
  yield call(scopeCheckHelper, action.scope);
}

function* requestPackageUpgrade({ featureCode, isChannel }) {
  const orgId = yield select(selectCurrentOrgId());
  const userId = yield select(selectCurrentUserId());
  const requestedAt = Date.now();

  yield call(
    sendToHelium,
    'requestPackageUpgrade',
    [orgId],
    { featureCode, requestedAt, userId },
    { suppressErrorMessage: true },
  );
  // if (errorMessage) return // removed this for new marketing flow and suppressed error message above!

  // If channel show success message. Otherwise change route silently.
  if (isChannel) {
    yield put(
      setAlert(
        'success',
        t(`A request has been successfully sent to your integrator.`),
      ),
    );
  } else {
    yield call(changeRoute, {
      route: accountPageRoute,
      options: { state: { forceSubscriptionEdit: true } },
    });
  }
}

function* rootSaga() {
  yield all([
    fork(watchClearStoreData),
    fork(watchRootContainerMounted),
    takeEvery(CHANGE_ROUTE, changeRoute),
    takeEvery(FIRE_AUTH_REDIRECT_CHECK, fireAuthRedirect),
    takeEvery(FIRE_SCOPE_CHECK, fireScopeCheck),
    takeEvery(REQUEST_AND_SET_IDENTITY, requestAndSetIdentity),
    takeEvery(REQUEST_PACKAGE_UPGRADE, requestPackageUpgrade),
    // fork(watchScopeCheck),
  ]);
}

export default rootSaga;
