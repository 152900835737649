import React from 'react';
import { SvgWrapper } from 'components/svgs/SvgWrapper/SvgWrapper';

export const P3Svg = ({ ...svgProps }) => (
  <SvgWrapper
    width="19.058823529411764"
    height="18"
    viewBox="0 0 72 68"
    fill="none"
    {...svgProps}
  >
    <path
      d="M29.5972 5.79268L29.597 5.79291L2.97046 53.7348C2.97042 53.7348 2.97038 53.7349 2.97035 53.735C0.24937 58.6332 3.57957 65.0017 9.33256 65.0017H62.5853C68.3255 65.0017 71.6749 58.6447 68.9475 53.735C68.9475 53.7349 68.9474 53.7348 68.9474 53.7348L42.3215 5.79292L42.3213 5.79266C39.515 0.741374 32.4097 0.730182 29.5972 5.79268Z"
      strokeWidth="4"
    />
    <path
      d="M22.37 33.41H28.91C31.27 33.41 33.14 33.9 34.52 34.88C35.92 35.84 36.62 37.42 36.62 39.62C36.62 41.78 35.91 43.41 34.49 44.51C33.07 45.61 31.25 46.16 29.03 46.16H26.48V53H22.37V33.41ZM28.76 42.89C31.32 42.89 32.6 41.8 32.6 39.62C32.6 38.58 32.27 37.83 31.61 37.37C30.97 36.91 29.98 36.68 28.64 36.68H26.48V42.89H28.76ZM45.0139 53.36C42.2339 53.36 40.0439 52.46 38.4439 50.66L40.3339 48.05C41.6939 49.37 43.1239 50.03 44.6239 50.03C45.6039 50.03 46.3839 49.82 46.9639 49.4C47.5639 48.96 47.8639 48.35 47.8639 47.57C47.8639 46.61 47.4739 45.89 46.6939 45.41C45.9339 44.93 44.5639 44.69 42.5839 44.69V41.75C44.2639 41.75 45.4539 41.52 46.1539 41.06C46.8739 40.58 47.2339 39.91 47.2339 39.05C47.2339 38.33 47.0139 37.78 46.5739 37.4C46.1339 37 45.5239 36.8 44.7439 36.8C44.0639 36.8 43.4339 36.94 42.8539 37.22C42.2939 37.5 41.7039 37.93 41.0839 38.51L39.0139 35.99C40.8939 34.37 42.8639 33.56 44.9239 33.56C46.8839 33.56 48.4439 34.02 49.6039 34.94C50.7639 35.84 51.3439 37.11 51.3439 38.75C51.3439 40.71 50.2739 42.13 48.1339 43.01V43.13C49.2939 43.47 50.2239 44.04 50.9239 44.84C51.6239 45.62 51.9739 46.61 51.9739 47.81C51.9739 48.95 51.6639 49.94 51.0439 50.78C50.4239 51.62 49.5839 52.26 48.5239 52.7C47.4639 53.14 46.2939 53.36 45.0139 53.36Z"
      fill={svgProps.stroke || 'black'}
    />
  </SvgWrapper>
);
