import { OpRawMenuItemType } from 'config/createRawMenuItems';
import { createContext, ReactNode, useMemo } from 'react';

interface RouteContextProps {
  routes: OpRawMenuItemType[];
}

export const RouteContext = createContext<RouteContextProps | undefined>(
  undefined,
);

export const RouteProvider = ({
  routes,
  children,
}: RouteContextProps & {
  children: ReactNode;
}) => {
  const contextValue = useMemo(
    () => ({
      routes,
    }),
    [routes],
  );

  return (
    <RouteContext.Provider value={contextValue}>
      {children}
    </RouteContext.Provider>
  );
};
