import { createBrowserHistory } from 'history';
import opConfig from 'openpathConfig';

const history = createBrowserHistory({
  getUserConfirmation(symbolKey, callback) {
    const trigger = window[Symbol.for(symbolKey)];
    if (trigger) {
      // if we've registered a callback to handle navigation, lets pass it
      return trigger(callback);
    }
    // otherwise just let navigation happen
    return callback(true);
  },
});

if (['dev', 'sandbox', 'mine'].includes(opConfig.ENV)) {
  let previousLocation = history.location;
  history.listen((newLocation) => {
    // Grab the search parameters from the previous and current locations
    const previousSearchParams = new URLSearchParams(previousLocation.search);
    const newSearchParams = new URLSearchParams(newLocation.search);

    const previousPRMatch = previousSearchParams.get('PR');
    const newPRMatch = newSearchParams.get('PR');

    if (!newPRMatch && previousPRMatch) {
      // grab all the search params from the new location
      const searchParams = new URLSearchParams(location.search);
      // add the PR param from the previous location
      searchParams.set('PR', previousPRMatch);
      // construct the modified pathname
      const modifiedPathname = `${location.pathname}?${searchParams.toString()}`;
      // replace with the modified pathname
      history.replace(modifiedPathname);
    }

    previousLocation = history.location;
  });
}

export default history;
