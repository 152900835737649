import React from 'react';
import {
  SvgWrapper,
  SvgWrapperProps,
} from 'components/svgs/SvgWrapper/SvgWrapper';

interface HomeOutlinedSvgProps extends Omit<SvgWrapperProps, 'children'> {}

export const HomeOutlinedSvg = ({ ...svgProps }: HomeOutlinedSvgProps) => (
  <SvgWrapper viewBox="0 0 18 18" {...svgProps}>
    <path d="M15.57 5.20994L10.71 0.669941C9.75 -0.220059 8.26 -0.220059 7.3 0.669941L2.43 5.20994C1.52 6.05994 1 7.25994 1 8.49994V15.4999C1 16.8799 2.12 17.9999 3.5 17.9999H14.5C15.88 17.9999 17 16.8799 17 15.4999V8.49994C17 7.25994 16.48 6.05994 15.57 5.20994ZM7 16.9999V11.4999C7 11.2199 7.22 10.9999 7.5 10.9999H10.5C10.78 10.9999 11 11.2199 11 11.4999V16.9999H7ZM16 15.4999C16 16.3299 15.33 16.9999 14.5 16.9999H12V11.4999C12 10.6699 11.33 9.99994 10.5 9.99994H7.5C6.67 9.99994 6 10.6699 6 11.4999V16.9999H3.5C2.67 16.9999 2 16.3299 2 15.4999V8.49994C2 7.52994 2.41 6.59994 3.11 5.93994L7.98 1.39994C8.27 1.12994 8.64 0.999941 9 0.999941C9.36 0.999941 9.74 1.12994 10.02 1.39994L14.89 5.93994C15.6 6.59994 16 7.52994 16 8.49994V15.4999Z" />
  </SvgWrapper>
);
