import { ComponentProps } from 'react';
import Space from 'antd/es/space';
import clsx from 'clsx';

import './OpSpace.scss';

interface OpSpaceProps extends ComponentProps<typeof Space> {
  block?: boolean;
}

export const OpSpace = ({
  children,
  className,
  block,
  ...spaceProps
}: OpSpaceProps) => {
  return (
    <Space
      className={clsx('op-space', className, { 'op-space--fullWidth': block })}
      {...spaceProps}
    >
      {children}
    </Space>
  );
};

// Make the sub components available as a property on the wrapper component
OpSpace.Compact = Space.Compact;
