import React from 'react';
import { SvgWrapper } from 'components/svgs/SvgWrapper/SvgWrapper';

export const P4Svg = ({ ...svgProps }) => (
  <SvgWrapper
    width="19.058823529411764"
    height="18"
    viewBox="0 0 72 68"
    fill="none"
    {...svgProps}
  >
    <path
      d="M29.5972 5.79268L29.597 5.79291L2.97046 53.7348C2.97042 53.7348 2.97038 53.7349 2.97035 53.735C0.24937 58.6332 3.57957 65.0017 9.33256 65.0017H62.5853C68.3255 65.0017 71.6749 58.6447 68.9475 53.735C68.9475 53.7349 68.9474 53.7348 68.9474 53.7348L42.3215 5.79292L42.3213 5.79266C39.515 0.741374 32.4097 0.730182 29.5972 5.79268Z"
      strokeWidth="4"
    />
    <path
      d="M22.37 53V33.44H28.91C30.35 33.44 31.65 33.63 32.81 34.01C33.97 34.39 34.89 35.04 35.57 35.96C36.27 36.86 36.62 38.09 36.62 39.65C36.62 41.15 36.28 42.38 35.6 43.34C34.92 44.3 34 45.02 32.84 45.5C31.7 45.96 30.43 46.19 29.03 46.19H26.48V53H22.37ZM26.48 42.89H28.76C30.06 42.89 31.02 42.62 31.64 42.08C32.26 41.52 32.57 40.71 32.57 39.65C32.57 38.55 32.23 37.79 31.55 37.37C30.89 36.93 29.92 36.71 28.64 36.71H26.48V42.89ZM46.6039 53V41.69C46.6039 41.11 46.6239 40.42 46.6639 39.62C46.7239 38.8 46.7639 38.1 46.7839 37.52H46.6639C46.4239 38.04 46.1739 38.56 45.9139 39.08C45.6539 39.6 45.3839 40.13 45.1039 40.67L42.2839 45.2H52.7239V48.35H38.3239V45.5L45.4639 33.95H50.4439V53H46.6039Z"
      fill={svgProps.stroke || 'black'}
    />
  </SvgWrapper>
);
