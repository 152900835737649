import { createSelector } from 'reselect';
import { fromJS } from 'immutable';
import { initialStateObject } from 'routes/AppContainer/reducer';
import { getWindowLocation } from 'utils/window';
// THIS IS TEMP AS WE CONVERT THINGS OVER

/**
 * Direct selector to the login state domain
 *
 * @returns {function} Function that returns the app state
 */
const selectGlobal = () => (state) => state.get('global');
const selectApp = () => (state) =>
  state.getIn(['app'], fromJS(initialStateObject));
const selectRoute = () => (state) => state.get('route');
const selectIsLoginComplete = () => (state) =>
  state.getIn(['app', 'loginComplete'], false);
const selectCurrentOrgName = () => (state) =>
  state.getIn(['app', 'currentOrgName']);

const selectCurrentOrgParentId = () => (state) =>
  state.getIn(['app', 'currentOrgParentId']);

/**
 * Other specific selectors
 */

const selectOrgUserImageUrl = () =>
  createSelector(selectApp(), (substate) => {
    const image = substate.get('currentOrgUserImageUrl');
    if (image) {
      return image.getIn(['picture', 'url']).replace('original', '192');
    }
    return null;
  });

// @TODO - this selector can be used in a bunch of the below ones,
// but should be tested first.
const selectCurrentIdentityInfo = () =>
  createSelector(selectApp(), (substate) =>
    substate.get('currentIdentityInfo'),
  );

// For authed routes, we first look at the identity language and set the language to that.
// If that is not set, we then fallback to the local storage setting.
// If that is not set, then we fallback to the browser locale.
export const selectCurrentIdentityLanguage = () =>
  createSelector(selectApp(), (substate) => {
    const info = substate.get('currentIdentityInfo');
    const identityLanguage = info ? info.get('language') : null;
    const localStorageLanguage = localStorage.getItem('i18nextLng');
    const browserLanguage = navigator.language || navigator.userLanguage;
    const defaultLanguage = 'en';

    return (
      identityLanguage ||
      localStorageLanguage ||
      browserLanguage ||
      defaultLanguage
    );
  });

const selectIdentityInitials = () =>
  createSelector(selectApp(), (substate) => {
    const info = substate.get('currentIdentityInfo');
    if (info && info.get('firstName') && info.get('lastName')) {
      return `${info.get('firstName')[0]}${info.get('lastName')[0]}`;
    }
    return 'OP';
  });

const selectIdentityName = () =>
  createSelector(selectApp(), (substate) => {
    const info = substate.get('currentIdentityInfo');
    if (info && info.get('firstName')) {
      return `${info.get('firstName')} ${info.get('lastName')}`;
    }
    return null;
  });

const selectIdentityEmail = () =>
  createSelector(selectApp(), (substate) => {
    const info = substate.get('currentIdentityInfo');
    if (info) {
      return info.get('email');
    }
    return null;
  });

const selectRefreshRequired = () =>
  createSelector(selectApp(), (substate) => substate.get('forceRefresh'));

const selectMaintenanceMode = () =>
  createSelector(selectApp(), (substate) => substate.get('maintenanceMode'));

const selectDisabledRoutes = () =>
  createSelector(selectApp(), (substate) => substate.get('disabledRoutes'));

const selectAlertStack = () =>
  createSelector(selectApp(), (substate) => substate.get('alertStack'));

const selectLoading = () =>
  createSelector(selectApp(), (substate) => substate.get('loading'));

const selectOrgDropDownVisibility = () =>
  createSelector(selectApp(), (substate) => substate.get('orgDropDownVisible'));

const selectLockdownDropDownVisibility = () =>
  createSelector(selectApp(), (substate) =>
    substate.get('lockdownDropDownVisible'),
  );

const selectTabLoading = () =>
  createSelector(selectApp(), (substate) => substate.get('tabLoading'));

const selectError = () =>
  createSelector(selectApp(), (substate) => substate.get('error'));

const selectCurrentIdentityId = () =>
  createSelector(selectApp(), (substate) => substate.get('currentIdentity'));

// const selectNextPathname = () => createSelector(
//   selectRoute(),
//   substate => null
//
//   //   if (substate.get('locationBeforeTransitions'))
//   //     if (substate.get('locationBeforeTransitions').get('state'))
//   //       return substate.get('locationBeforeTransitions').get('state').get('nextPathname')
//   //   return null}
// )

const selectInviteToken = () => () => {
  const param = getWindowLocation()
    .search.replace('?', '')
    .split('&')
    .find((p) => p.split('=')[0] === 'inviteToken');
  return param ? param.split('=')[1] : null;
};

// selectLocationState expects a plain JS object for the routing state
const selectLocationState = () => {
  let prevRoutingState;
  let prevRoutingStateJS;

  return (state) => {
    const routingState = state.get('route'); // or state.route

    if (!routingState.equals(prevRoutingState)) {
      prevRoutingState = routingState;
      prevRoutingStateJS = routingState.toJS();
    }

    return prevRoutingStateJS;
  };
};

const selectErrorPageMessage = () =>
  createSelector(selectApp(), (substate) => substate.get('errorMessage'));

const selectErrorPageCallToAction = () =>
  createSelector(selectApp(), (substate) => substate.get('errorCta'));

const selectOrgIsLicenseBased = () =>
  createSelector(selectApp(), (substate) => substate.get('isLicenseBased'));

export const selectConfirmationProps = () =>
  createSelector(selectApp(), (substate) => substate.get('confirmationProps'));

export const selectIntercomNotificationsAreEnabled = () =>
  createSelector(selectApp(), (app) =>
    app.get('intercomNotificationsAreEnabled'),
  );

export const selectCurrentUserPreferences = () =>
  createSelector(selectApp(), (app) => app.get('currentUserPreferences'));

export const selectVideoTimestampPreference = () =>
  createSelector(selectCurrentUserPreferences(), (currentUserPreferences) =>
    currentUserPreferences.get('videoTimestamp'),
  );

export const selectPreferredMeasurementUnitsPreference = () =>
  createSelector(selectCurrentUserPreferences(), (currentUserPreferences) =>
    currentUserPreferences.get('preferredMeasurementUnits'),
  );

export const selectPlayAlarmNotificationSoundPreference = () =>
  createSelector(selectCurrentUserPreferences(), (currentUserPreferences) =>
    currentUserPreferences.get('playAlarmNotificationSound'),
  );

export const selectAlarmNotificationSoundVolumePreference = () =>
  createSelector(selectCurrentUserPreferences(), (currentUserPreferences) =>
    currentUserPreferences.get('alarmNotificationSoundVolume'),
  );

export const selectPrefetchedVideocallAuthForDevices = () =>
  createSelector(selectApp(), (app) =>
    app.get('prefetchedVideocallAuthForDevices'),
  );

export {
  selectOrgIsLicenseBased,
  selectIsLoginComplete,
  selectCurrentOrgName,
  selectGlobal,
  selectApp,
  selectLoading,
  selectTabLoading,
  selectError,
  selectCurrentIdentityId,
  selectLocationState,
  selectInviteToken,
  selectOrgDropDownVisibility,
  selectLockdownDropDownVisibility,
  selectAlertStack,
  selectMaintenanceMode,
  selectDisabledRoutes,
  selectErrorPageMessage,
  selectRefreshRequired,
  selectRoute,
  selectCurrentOrgParentId,
  selectOrgUserImageUrl,
  selectIdentityInitials,
  selectIdentityName,
  selectIdentityEmail,
  selectCurrentIdentityInfo,
  selectErrorPageCallToAction,
};
