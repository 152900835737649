import React from 'react';
import {
  SvgWrapper,
  SvgWrapperProps,
} from 'components/svgs/SvgWrapper/SvgWrapper';

interface CameraOutlinedSvgProps extends Omit<SvgWrapperProps, 'children'> {}

export const CameraOutlinedSvg = ({ ...svgProps }: CameraOutlinedSvgProps) => (
  <SvgWrapper viewBox="0 0 18 18" {...svgProps}>
    <path d="M17.5 2C17.776 2 18 2.224 18 2.5V3.5C18 4.314 17.603 5.032 16.998 5.489C16.998 5.493 17 5.496 17 5.5V8C17 12.411 13.411 16 9 16C4.589 16 1 12.411 1 8L1.001 5.4945C0.397 5.032 0 4.314 0 3.5V2.5C0 2.224 0.224 2 0.5 2H17.5ZM16 6H2V8C2 11.859 5.14 15 9 15C12.86 15 16 11.859 16 8V6ZM9 8C10.378 8 11.5 9.121 11.5 10.5C11.5 11.879 10.378 13 9 13C7.622 13 6.5 11.879 6.5 10.5C6.5 9.121 7.622 8 9 8ZM9 9C8.173 9 7.5 9.673 7.5 10.5C7.5 11.327 8.173 12 9 12C9.827 12 10.5 11.327 10.5 10.5C10.5 9.673 9.827 9 9 9ZM17 3H1V3.5C1 4.327 1.673 5 2.5 5H15.5C16.327 5 17 4.327 17 3.5V3Z" />
  </SvgWrapper>
);
