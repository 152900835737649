import { createSelector } from 'reselect';
import {
  getActiveScopes,
  verifyScope,
  substituteOrgIdInScope,
} from 'utils/redirects';
import { getRawMenuItems, getItemConfig } from 'config/utils';
import { selectActiveItem } from 'containers/NavMenu/selectors';
import { selectCurrentOrgParentId } from 'routes/AppContainer/selectors';
import { includesAnyPortalScope } from 'utils/helpers';
import { fromJS } from 'immutable';

const selectCurrentOrg = () => (state) => state.getIn(['app', 'currentOrg']);
const selectAppError = () => (state) => state.getIn(['app', 'error']);
const selectAccessTokenScopes = () => (state) =>
  state.getIn(['accessToken', 'scopes']);
export const selectAccessToken = () => (state) =>
  state.getIn(['accessToken', 'token']);
export const selectIdentityId = (state) =>
  state.getIn(['accessToken', 'identity', 'id']);
const selectProcessedScopes = () => (state) =>
  state.getIn(['accessToken', 'processedScopes']);
const selectRemoteFeatureFlags = () => (state) =>
  state.getIn(['accessToken', 'remoteFeatureFlags']);
const selectMfaCredentials = () => (state) =>
  state.getIn(['accessToken', 'mfaCredentials']);

export const selectOrgsForIdentityUsersWithPortalScope = () =>
  createSelector(selectAccessTokenScopes(), (substate) =>
    substate.reduce((acc, curr) => {
      if (
        curr.getIn(['org', 'id']) &&
        includesAnyPortalScope(curr.get('scope'))
      ) {
        // Org is a parent org if it has a package with the subOrgs feature
        const isParentOrg = curr
          .getIn(['org', 'packagePlans'])
          ?.some((pp) =>
            pp
              .getIn(['package', 'packageFeatures'])
              ?.some((pf) => pf.getIn(['feature', 'code']) === 'subOrgs'),
          );

        return acc.push(curr.get('org').set('isParentOrg', isParentOrg));
      }

      return acc;
    }, fromJS([])),
  );

export const selectOrgIdsForIdentityUsersWithPortalScope = () =>
  createSelector(selectOrgsForIdentityUsersWithPortalScope(), (orgs) =>
    orgs.map((org) => org.get('id')),
  );

// Helper to just get the org id
const selectCurrentOrgId = () =>
  createSelector(selectCurrentOrg(), (currentOrg) =>
    currentOrg ? Number(currentOrg) : null,
  );

// Returns a flat list of scopes from all applicable sources; see
// additional comments at getActiveScopes in utils/redirects.
const selectActiveScopes = () =>
  createSelector(
    [
      selectCurrentOrg(),
      selectAccessTokenScopes(),
      selectProcessedScopes(),
      selectCurrentOrgParentId(),
    ],
    (currentOrg, scopes, processedScopes, parentId) =>
      getActiveScopes(
        currentOrg,
        scopes,
        processedScopes.get(String(parentId)),
      ),
  );

// OR if any of the orgs in tokenScopeList have s-o:r or s-o:w scope AND that org has the Sub-Orgs feature
// TOOD remove this whole selector when OPAC-2987 rolls around
export const selectIfAnyOrgsHaveSubOrgFeature = () =>
  createSelector([selectProcessedScopes()], (processedScopes) =>
    processedScopes.find(
      (s) => s.get('hasSubOrgFeature') && s.get('partnerScopes').size,
    ),
  );

const selectHasMasterModeAccess = () =>
  createSelector(
    [selectCurrentOrg(), selectAccessTokenScopes()],
    (currentOrg, scopes) => {
      const activeScopes = getActiveScopes(currentOrg, scopes);
      const validScopePrefixes = [
        'o:', // o:r, o:w
        'o-', // e.g. o-basic:r, o-basic:w, o-acu:r, o-acu:w, and more later...
        'i:', // i:r, i:w
        'pc:', // pc:r, pc:w
      ];
      const hasMasterModeScope = activeScopes.some((s) =>
        validScopePrefixes.some((p) => s.startsWith(p)),
      );
      return hasMasterModeScope;
    },
  );

const selectHasGeneralOrgSwitchAccess = () =>
  createSelector(
    [
      selectCurrentOrg(),
      selectAccessTokenScopes(),
      selectIfAnyOrgsHaveSubOrgFeature(),
    ],
    (currentOrg, scopes, anOrgHasSubOrgsFeature) => {
      // it's an OR check, so if we have this we can early exit
      if (
        anOrgHasSubOrgsFeature ||
        scopes.filter((scope) => scope.getIn(['org', 'id'])).size > 1
      ) {
        return true;
      }
      const activeScopes = getActiveScopes(currentOrg, scopes);

      const validScopePrefixes = [
        'o:', // o:r, o:w

        // NOTE: specifically NOT included here are the various o-
        // granular scopes like o-basic:r, etc., and the i: scopes and
        // the pc: scopes, because while those carry some permission for
        // Master Mode menu functions, they don't (by themselves) carry
        // enough permission to org-switch and have access to the
        // org-mode portal for an arbitrary org
      ];
      const hasOrgSwitchScope = activeScopes.some((s) =>
        validScopePrefixes.some((p) => s.startsWith(p)),
      );
      return hasOrgSwitchScope;
    },
  );

const selectIsFullSupportAccessRequiredForOrgSwitch = () =>
  createSelector([selectProcessedScopes()], (processedScopes) =>
    processedScopes.getIn(['null', 'isFullSupportAccessRequired']),
  );

const selectActiveAllowedScopes = () =>
  createSelector(
    [selectCurrentOrg(), selectActiveItem()],
    (currentOrg, activeItem) => {
      if (!activeItem) return [];
      const allowedScopesRaw = getItemConfig(
        getRawMenuItems(),
        activeItem.get('route'),
      ).scope;
      if (!allowedScopesRaw) return [];
      const allowedScopes = substituteOrgIdInScope(
        currentOrg,
        allowedScopesRaw,
      );
      return allowedScopes;
    },
  );

const selectHasValidScopeAccess = () =>
  createSelector(
    [selectActiveAllowedScopes(), selectActiveScopes()],
    (allowedScopes, activeScopes) => verifyScope(activeScopes, allowedScopes),
  );

const selectHasValidScopeWriteAccess = () =>
  createSelector(
    [selectActiveAllowedScopes(), selectActiveScopes()],
    (allowedScopes, activeScopes) => {
      const writeScopes = allowedScopes.filter((s) => s.includes(':w'));
      return verifyScope(activeScopes, writeScopes);
    },
  );

const selectCurrentUser = () =>
  createSelector(
    [selectAccessTokenScopes(), selectCurrentOrgId()],
    (scopes = [], currentOrgId) => {
      const currentOrgUser = scopes.find(
        (s) => String(s.getIn(['org', 'id'])) === String(currentOrgId),
      );
      if (currentOrgUser) return currentOrgUser.getIn(['user']);
      return null;
    },
  );

const selectCurrentUserId = () =>
  createSelector([selectCurrentUser()], (currentUser) => {
    if (currentUser) return currentUser.get('id');
    return null;
  });

const selectCurrentUserOpal = () =>
  createSelector([selectCurrentUser()], (currentUser) => {
    if (currentUser) return currentUser.get('opal');
    return null;
  });

const selectUserForOrg = (orgId) =>
  createSelector([selectAccessTokenScopes()], (scopes = []) => {
    const orgUser = scopes.find(
      (s) => String(s.getIn(['org', 'id'])) === String(orgId),
    );
    if (orgUser) return orgUser.getIn(['user']);
    return null;
  });

const selectUserIdForOrg = (orgId) =>
  createSelector([selectUserForOrg(orgId)], (userForOrg) => {
    if (userForOrg) return userForOrg.get('id');
    return null;
  });

const selectUserOpalForOrg = (orgId) =>
  createSelector([selectUserForOrg(orgId)], (userForOrg) => {
    if (userForOrg) return userForOrg.get('opal');
    return null;
  });

export {
  selectAccessTokenScopes,
  selectCurrentOrgId,
  selectActiveScopes,
  selectCurrentOrg,
  selectAppError,
  selectHasMasterModeAccess,
  selectHasGeneralOrgSwitchAccess,
  selectIsFullSupportAccessRequiredForOrgSwitch,
  selectHasValidScopeAccess,
  selectHasValidScopeWriteAccess,
  selectCurrentUserId,
  selectCurrentUserOpal,
  selectUserForOrg,
  selectUserIdForOrg,
  selectUserOpalForOrg,
  selectProcessedScopes,
  selectRemoteFeatureFlags,
  selectMfaCredentials,
};
