export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';
export const TYPING_DEBOUNCE_TIME = 300;
export const UNIT_TO_SECONDS_LOOKUP_TABLE = {
  seconds: 1,
  minutes: 60,
  hours: 3600,
  days: 86400,
  weeks: 604800,
  months: 2592000,
  years: 31556926,
};
export const SECONDS_TO_UNIT_LOOKUP_TABLE = {
  1: 'seconds',
  60: 'minutes',
  3600: 'hours',
  86400: 'days',
  604800: 'weeks',
  2592000: 'months',
  31556926: 'years',
};
export const VIDEO_READER_ACU_MODEL_IDS = [9, 10];
export const MERCURY_EXPANSION_BOARD_ACU_MODEL_IDS = [
  11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 25, 26, 27, 28,
];
export const HELIUM_LIMIT_MAX = 1000;
export const INITIAL_ACTIVITY_RANGE_HOURS = 1;
export const MAX_ACTIVITY_RANGE_HOURS = 12;
export const CREDENTIAL_IDS = {
  mobile: 1,
  card: 2,
  pin: 3,
  mifare: 4,
  desfire: 5,
  cloud: 6,
  fobAllegion: 7,
  licensePlate: 9,
  wallet: 10,
};
