/**
 * Library used to enable/disable logging. Change value of isLoggingEnabled to toggle whther logs are created or not.
 */

// External libraries

// Local libraries

export class Logger {
  static isLoggingEnabled = false;

  static log(message: string): void {
    if (this.isLoggingEnabled) {
      console.log(message);
    }
  }
}
