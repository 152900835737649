import React from 'react';
import {
  SvgWrapper,
  SvgWrapperProps,
} from 'components/svgs/SvgWrapper/SvgWrapper';

interface QuestionMarkOutlinedSvgProps
  extends Omit<SvgWrapperProps, 'children'> {}

export const QuestionMarkOutlinedSvg = ({
  ...svgProps
}: QuestionMarkOutlinedSvgProps) => (
  <SvgWrapper viewBox="0 0 18 18" {...svgProps}>
    <path d="M9 1C13.411 1 17 4.589 17 9C17 13.411 13.411 17 9 17C4.589 17 1 13.411 1 9C1 4.589 4.589 1 9 1ZM9 2C5.14 2 2 5.14 2 9C2 12.859 5.14 16 9 16C12.859 16 16 12.859 16 9C16 5.14 12.859 2 9 2ZM9 13C9.276 13 9.5 13.224 9.5 13.5C9.5 13.776 9.276 14 9 14C8.724 14 8.5 13.776 8.5 13.5C8.5 13.224 8.724 13 9 13ZM9.724 4.093C10.656 4.334 11.415 5.092 11.657 6.025C12.026 7.456 11.264 8.887 9.883 9.355C9.698 9.417 9.5 9.57 9.5 9.808V11.5C9.5 11.776 9.276 12 9 12C8.724 12 8.5 11.776 8.5 11.5V9.808C8.5 9.176 8.917 8.626 9.562 8.408C10.31 8.154 10.959 7.321 10.688 6.276C10.537 5.689 10.06 5.212 9.474 5.061C8.925 4.921 8.363 5.03 7.93 5.365C7.498 5.7 7.25 6.205 7.25 6.75C7.25 7.026 7.026 7.25 6.75 7.25C6.474 7.25 6.25 7.026 6.25 6.75C6.25 5.893 6.639 5.1 7.317 4.575C7.996 4.049 8.875 3.874 9.724 4.093Z" />
  </SvgWrapper>
);
