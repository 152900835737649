import { WirelessLockGatewayInviteStates } from 'containers/AllegionInviter/constants';
import { fromJS } from 'immutable';
import {
  SET_USER_GROUPS,
  SET_ORG,
  SET_GROUPS,
  SET_GROUP,
  SET_SITES,
  SET_ZONES,
  SET_ZONE,
  SET_ZONE_GROUPS,
  SET_ENTRIES,
  SET_USERS,
  SET_FILTERED_USERS_COUNT,
  SET_IDENTITY_PROVIDERS,
  SET_ENTRY_STATES,
  SET_TRIGGER_METHODS,
  SET_IOS,
  SET_SCHEDULES,
  SET_GROUP_SCHEDULES,
  SET_ACUS,
  SET_SCOPE_RESOURCES,
  SET_ROLES,
  SET_ROLE,
  SET_ACTIVITY,
  SET_ALARM_ACTIVITY,
  SET_VISUAL_ACTIVITY,
  SET_USER_ACTIVITY,
  SET_USER_CREDENTIALS,
  SET_IDENTITY_PROVIDER_GROUPS,
  SET_IDENTITY_PROVIDER_GROUP_RELATIONS,
  SET_ALL_ACU_ENTRIES_USERS,
  SET_ENTRY_USER_SCHEDULES,
  SET_USER_ENTRIES,
  SET_CREDENTIAL_TYPES,
  SET_CREDENTIALS,
  SET_CARD_FORMATS,
  SET_SCHEDULE_TYPES,
  SET_SCHEDULE_EVENTS,
  SET_AUDIT_LOGS,
  SET_SHARED_USERS,
  SET_ACU_PORTS,
  SET_REQUEST_TYPES,
  SET_PROTON_STATUSES,
  SET_NAMESPACES,
  SET_TOTAL_USERS_COUNT,
  SET_VIDEO_PROVIDERS,
  SET_WIRELESS_LOCKS,
  SET_WIRELESS_LOCK_PROVIDERS,
  SET_NETSUITE_PACKAGE_PLANS,
  SET_ALLEGION_OPERATORS,
  SET_ALLEGION_INVITE_STATE,
  SET_ALARM_CONFIGURATIONS,
} from './constants';

const initialOrgState = fromJS({});
function orgReducer(state = initialOrgState, action) {
  switch (action.type) {
    case SET_ORG:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialNetsuitePackagePlansState = fromJS([]);
function netsuitePackagePlansReducer(
  state = initialNetsuitePackagePlansState,
  action,
) {
  switch (action.type) {
    case SET_NETSUITE_PACKAGE_PLANS:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialEntriesState = fromJS([]);
function entriesReducer(state = initialEntriesState, action) {
  switch (action.type) {
    case SET_ENTRIES:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialRolesReducer = fromJS([]);
function rolesReducer(state = initialRolesReducer, action) {
  switch (action.type) {
    case SET_ROLES:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialRoleReducer = fromJS([]);
function roleReducer(state = initialRoleReducer, action) {
  switch (action.type) {
    case SET_ROLE:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialGroupsState = fromJS([]);
function groupsReducer(state = initialGroupsState, action) {
  switch (action.type) {
    case SET_GROUPS:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialUserGroupsState = fromJS([]);
function userGroupsReducer(state = initialUserGroupsState, action) {
  switch (action.type) {
    case SET_USER_GROUPS:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialGroupState = fromJS({});
function groupReducer(state = initialGroupState, action) {
  switch (action.type) {
    case SET_GROUP:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialSitesState = fromJS([]);
function sitesReducer(state = initialSitesState, action) {
  switch (action.type) {
    case SET_SITES:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialZonesState = fromJS([]);
function zonesReducer(state = initialZonesState, action) {
  switch (action.type) {
    case SET_ZONES:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialZoneState = fromJS({});
function zoneReducer(state = initialZoneState, action) {
  switch (action.type) {
    case SET_ZONE:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialZoneGroupsState = fromJS([]);
function zoneGroupsReducer(state = initialZoneGroupsState, action) {
  switch (action.type) {
    case SET_ZONE_GROUPS:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialUsersState = fromJS([]);
function usersReducer(state = initialUsersState, action) {
  switch (action.type) {
    case SET_USERS: {
      return fromJS(action.data);
    }
    default:
      return state;
  }
}

const initialIdentityProvidersState = fromJS([]);
function identityProvidersReducer(
  state = initialIdentityProvidersState,
  action,
) {
  switch (action.type) {
    case SET_IDENTITY_PROVIDERS: {
      return fromJS(action.data);
    }
    default:
      return state;
  }
}

const initialEntryStatesState = fromJS([]);
function entryStatesReducer(state = initialEntryStatesState, action) {
  switch (action.type) {
    case SET_ENTRY_STATES:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialTriggerMethodsState = fromJS([]);
function triggerMethodsReducer(state = initialTriggerMethodsState, action) {
  switch (action.type) {
    case SET_TRIGGER_METHODS:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialIosState = fromJS([]);
function iosReducer(state = initialIosState, action) {
  switch (action.type) {
    case SET_IOS:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialSchedulesState = fromJS([]);
function schedulesReducer(state = initialSchedulesState, action) {
  switch (action.type) {
    case SET_SCHEDULES:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialGroupSchedulesState = fromJS([]);
function groupSchedulesReducer(state = initialGroupSchedulesState, action) {
  switch (action.type) {
    case SET_GROUP_SCHEDULES:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialAcusState = fromJS([]);
function acusReducer(state = initialAcusState, action) {
  switch (action.type) {
    case SET_ACUS:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialIdentityProviderGroupsState = fromJS([]);
function identityProviderGroupsReducer(
  state = initialIdentityProviderGroupsState,
  action,
) {
  switch (action.type) {
    case SET_IDENTITY_PROVIDER_GROUPS:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialIdentityProviderGroupRelationsState = fromJS([]);
function identityProviderGroupRelationsReducer(
  state = initialIdentityProviderGroupRelationsState,
  action,
) {
  switch (action.type) {
    case SET_IDENTITY_PROVIDER_GROUP_RELATIONS:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialVideoProvidersState = fromJS([]);
function videoProvidersReducer(state = initialVideoProvidersState, action) {
  switch (action.type) {
    case SET_VIDEO_PROVIDERS:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialAllAcuEntriesUsersState = fromJS([]);
function allAcuEntriesUsersReducer(
  state = initialAllAcuEntriesUsersState,
  action,
) {
  switch (action.type) {
    case SET_ALL_ACU_ENTRIES_USERS:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialEntryUserSchedulesState = fromJS([]);
function entryUserSchedulesReducer(
  state = initialEntryUserSchedulesState,
  action,
) {
  switch (action.type) {
    case SET_ENTRY_USER_SCHEDULES:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialUserEntriesState = fromJS([]);
function userEntriesReducer(state = initialUserEntriesState, action) {
  switch (action.type) {
    case SET_USER_ENTRIES:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialCredentialTypesState = fromJS([]);
function credentialTypesReducer(state = initialCredentialTypesState, action) {
  switch (action.type) {
    case SET_CREDENTIAL_TYPES:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialCredentialsState = fromJS([]);
function credentialsReducer(state = initialCredentialsState, action) {
  switch (action.type) {
    case SET_CREDENTIALS:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialCardFormatsState = fromJS([]);
function cardFormatsReducer(state = initialCardFormatsState, action) {
  switch (action.type) {
    case SET_CARD_FORMATS:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialScheduleTypesState = fromJS([]);
function scheduleTypesReducer(state = initialScheduleTypesState, action) {
  switch (action.type) {
    case SET_SCHEDULE_TYPES:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialScheduleEventsState = fromJS([]);
export function scheduleEventsReducer(
  state = initialScheduleEventsState,
  action,
) {
  switch (action.type) {
    case SET_SCHEDULE_EVENTS:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialAuditLogsState = fromJS([]);
export function auditLogsReducer(state = initialAuditLogsState, action) {
  switch (action.type) {
    case SET_AUDIT_LOGS:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialSharedUsersState = fromJS([]);
export function sharedUsersReducer(state = initialSharedUsersState, action) {
  switch (action.type) {
    case SET_SHARED_USERS:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialAcuPortsState = fromJS([]);
export function acuPortsReducer(state = initialAcuPortsState, action) {
  switch (action.type) {
    case SET_ACU_PORTS:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialRequestTypesState = fromJS([]);
export function requestTypesReducer(state = initialRequestTypesState, action) {
  switch (action.type) {
    case SET_REQUEST_TYPES:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialProtonStatusesState = fromJS([]);
export function protonStatusesReducer(
  state = initialProtonStatusesState,
  action,
) {
  switch (action.type) {
    case SET_PROTON_STATUSES:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialNamespacesState = fromJS([]);
export function namespacesReducer(state = initialNamespacesState, action) {
  switch (action.type) {
    case SET_NAMESPACES:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialScopeResourcesState = fromJS([]);
function scopeResourcesReducer(state = initialScopeResourcesState, action) {
  switch (action.type) {
    case SET_SCOPE_RESOURCES:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialActivityState = fromJS([]);
function activityReducer(state = initialActivityState, action) {
  switch (action.type) {
    case SET_ACTIVITY:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialAlarmActivityState = fromJS([]);
function alarmActivityReducer(state = initialAlarmActivityState, action) {
  switch (action.type) {
    case SET_ALARM_ACTIVITY:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialVisualActivityState = fromJS([]);
function visualActivityReducer(state = initialVisualActivityState, action) {
  switch (action.type) {
    case SET_VISUAL_ACTIVITY:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialUserActivityState = fromJS([]);
function activityUserReducer(state = initialUserActivityState, action) {
  switch (action.type) {
    case SET_USER_ACTIVITY:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialUserCredentialsState = fromJS([]);
function userCredentialsReducer(state = initialUserCredentialsState, action) {
  switch (action.type) {
    case SET_USER_CREDENTIALS:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialWirelessLocksState = fromJS([]);
function wirelessLocksReducer(state = initialWirelessLocksState, action) {
  switch (action.type) {
    case SET_WIRELESS_LOCKS:
      return fromJS(action.data);
    default:
      return state;
  }
}

const initialWirelessLockProvidersState = fromJS({
  hasLoaded: false,
  data: [],
});
function wirelessLockProvidersReducer(
  state = initialWirelessLockProvidersState,
  action,
) {
  switch (action.type) {
    case SET_WIRELESS_LOCK_PROVIDERS:
      return state.merge(fromJS({ data: action.data, hasLoaded: true }));
    default:
      return state;
  }
}

const initialAllegionOperatorsState = fromJS({ hasLoaded: false, data: [] });
function allegionOperatorsReducer(
  state = initialAllegionOperatorsState,
  action,
) {
  switch (action.type) {
    case SET_ALLEGION_OPERATORS:
      return state.merge(
        fromJS({ data: fromJS(action.data), hasLoaded: true }),
      );
    default:
      return state;
  }
}

const initialAllegionInviteState = WirelessLockGatewayInviteStates.UNDEFINED;
function allegionInviterReducer(state = initialAllegionInviteState, action) {
  switch (action.type) {
    case SET_ALLEGION_INVITE_STATE:
      return action.data;
    default:
      return state;
  }
}

const initialAlarmConfigurationsReducer = fromJS([]);
function alarmConfigurationsReducer(
  state = initialAlarmConfigurationsReducer,
  action,
) {
  switch (action.type) {
    case SET_ALARM_CONFIGURATIONS:
      return fromJS(action.data);
    default:
      return state;
  }
}

export function filteredUsersCountReducer(state = null, { type, data }) {
  switch (type) {
    case SET_FILTERED_USERS_COUNT:
      return data;
    default:
      return state;
  }
}

export function totalUsersCountReducer(state = null, { type, data }) {
  switch (type) {
    case SET_TOTAL_USERS_COUNT:
      return data;
    default:
      return state;
  }
}

export {
  orgReducer,
  netsuitePackagePlansReducer,
  groupsReducer,
  groupReducer,
  sitesReducer,
  zonesReducer,
  zoneReducer,
  zoneGroupsReducer,
  entriesReducer,
  usersReducer,
  identityProvidersReducer,
  entryStatesReducer,
  triggerMethodsReducer,
  iosReducer,
  schedulesReducer,
  groupSchedulesReducer,
  acusReducer,
  scopeResourcesReducer,
  rolesReducer,
  roleReducer,
  activityReducer,
  alarmActivityReducer,
  visualActivityReducer,
  activityUserReducer,
  identityProviderGroupsReducer,
  identityProviderGroupRelationsReducer,
  videoProvidersReducer,
  allAcuEntriesUsersReducer,
  entryUserSchedulesReducer,
  userEntriesReducer,
  credentialTypesReducer,
  credentialsReducer,
  cardFormatsReducer,
  scheduleTypesReducer,
  userCredentialsReducer,
  userGroupsReducer,
  wirelessLocksReducer,
  wirelessLockProvidersReducer,
  allegionOperatorsReducer,
  allegionInviterReducer,
  alarmConfigurationsReducer,
};

// This can be used instead of all the others above
// The actionType should be imported from the OrgContainer constants
export const createOrgContainerReducer =
  (initialState, actionType) =>
  (state = fromJS(initialState), { type, data }) =>
    type === actionType ? fromJS(data) : state;
