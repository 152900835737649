export const SparkIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.70312 15.4609C7.55729 15.4609 7.43229 15.4115 7.32812 15.3125C7.22396 15.2188 7.15885 15.0938 7.13281 14.9375C7.01302 14.0729 6.88542 13.3255 6.75 12.6953C6.61458 12.0651 6.45052 11.5312 6.25781 11.0938C6.0651 10.651 5.82031 10.2839 5.52344 9.99219C5.23177 9.69531 4.86719 9.45312 4.42969 9.26562C3.99219 9.07812 3.46354 8.92188 2.84375 8.79688C2.22396 8.66667 1.48958 8.54427 0.640625 8.42969C0.479167 8.41406 0.346354 8.35417 0.242188 8.25C0.143229 8.14062 0.09375 8.01042 0.09375 7.85938C0.09375 7.70833 0.143229 7.58073 0.242188 7.47656C0.346354 7.36719 0.479167 7.30208 0.640625 7.28125C1.65625 7.17188 2.51042 7.04167 3.20312 6.89062C3.90104 6.73438 4.47396 6.52083 4.92188 6.25C5.375 5.97917 5.73958 5.61719 6.01562 5.16406C6.29167 4.70573 6.51302 4.11979 6.67969 3.40625C6.84635 2.69271 6.9974 1.8151 7.13281 0.773438C7.15885 0.617188 7.22396 0.492188 7.32812 0.398438C7.43229 0.304688 7.55729 0.257812 7.70312 0.257812C7.84896 0.257812 7.97396 0.304688 8.07812 0.398438C8.18229 0.492188 8.2474 0.617188 8.27344 0.773438C8.40885 1.8151 8.5599 2.69271 8.72656 3.40625C8.89323 4.11979 9.11198 4.70573 9.38281 5.16406C9.65885 5.61719 10.0208 5.97917 10.4688 6.25C10.9219 6.52083 11.4974 6.73438 12.1953 6.89062C12.8932 7.04167 13.75 7.17188 14.7656 7.28125C14.9219 7.30208 15.0521 7.36719 15.1562 7.47656C15.2604 7.58073 15.3125 7.70833 15.3125 7.85938C15.3125 8.01042 15.2604 8.14062 15.1562 8.25C15.0521 8.35417 14.9219 8.41406 14.7656 8.42969C13.75 8.54427 12.8932 8.67969 12.1953 8.83594C11.4974 8.98698 10.9219 9.19792 10.4688 9.46875C10.0208 9.73958 9.65885 10.1042 9.38281 10.5625C9.11198 11.0156 8.89323 11.599 8.72656 12.3125C8.5599 13.0208 8.40885 13.8958 8.27344 14.9375C8.2474 15.0938 8.18229 15.2188 8.07812 15.3125C7.97396 15.4115 7.84896 15.4609 7.70312 15.4609Z"
      fill="white"
    />
  </svg>
);
