import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import { InitOptions } from 'i18next';
import { v4 as uuidv4 } from 'uuid';
import { WebAudio } from 'utils/audio';
import i18n, { i18nInitOptions, i18nPostInit } from './i18n/i18n';
import App from './App';
import { configureStore } from './store/index';
import { init as sentryInit } from './sentry';
import { loadShims } from './shims';

// Initialize Sentry as soon as possible
sentryInit();
loadShims();

const store = configureStore({}) as Store;

// Initialize WebAudio capabilities
WebAudio.initialize();

i18n.init(i18nInitOptions as InitOptions);

i18nPostInit(i18n);

let sessionId = sessionStorage.getItem('sessionId');
if (!sessionId) {
  sessionId = uuidv4();
  sessionStorage.setItem('sessionId', sessionId);
}

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  // Need to wrap in Provider so we have access to useDispatch in the App (needed for setAlert)
  <Provider store={store}>
    <App />
  </Provider>,
);
