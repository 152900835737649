export const sharedRoutes = {
  redirectToHome: '/',
  pageNotFound: '/page-not-found',
};

export const ROUTE_ORG_ID_TEMPLATE = ':orgId';
export const SCOPE_ORG_ID_TEMPLATES = [
  '{orgId}', // How it is represented in Platinum
  '{params.orgId}', // How it is represented in Helium
];

export const SCOPE_IDENTITY_ID_TEMPLATES = [
  '{identityId}', // How it is represented in Platinum
  '{params.identityId}', // How it is represented in Helium
];
